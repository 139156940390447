.status {
  display: flex;
  justify-content: flex-end;
}

.row {
  padding: 5px 0;
  display: flex;
  align-items: center;
  color: var(--bodyText-dark);
}

.label {
  font-family: var(--fontUltra);
}

.label_span {
  font-family: var(--fontMedium);
  word-break: break-all;
}

.remove {
  margin-top: 10px;
  display: flex;
  flex-flow: row-reverse;
}

.remove_message_list {
  list-style: none;
  padding: 0;
  margin: var(--gutterSpacing) 0 0;
}

.remove_message_list_item {
  list-style: none;
  margin: 10px 0 0;
}

.remove_message .remove_message_label {
  font-family: var(--fontUltra);
}

.download {
  color: var(--primaryColor);
}
