.container {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
  justify-content: space-between;
  width: 100%;
  margin-top: var(--gutterSpacing);
}

.users {
  flex-basis: var(--columnThird);
  margin-bottom: 2%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_action {
  display: flex;
  flex-basis: 250px;
}

.loading {
  height: 20px;
}

.pagination {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin-top: 10px;
  padding: var(--gutterSpacing);
}
.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}
.sub_row {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font: 1rem var(--fontNeoBold);
  margin-left: 0;
  margin-bottom: 1.5rem;
}

.toggle_wrapper,
.list_action_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.start_time {
  white-space: nowrap;
}

.raw_objet_tooltip:after {
  width: 6rem;
}

@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }

  .users {
    flex-basis: var(--columnHalf);
  }
}
