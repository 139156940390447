.row {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 0.4rem;
  word-break: break-word;
}
.row_half_card {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.label {
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}
.value {
  font: 1rem var(--fontNeoBold);
  margin-left: 0;
}

@media (max-width: 767px) {
  .row {
    flex-basis: 100%;
  }
}
@media (min-width: 768px) {
  .row,
  .row_half,
  .row_half_card {
    flex-basis: 45%;
  }
}

@media (min-width: 1366px) {
  .row {
    flex-basis: 23%;
  }
  .row_half {
    flex-basis: 48%;
  }
  .row_half_card {
    flex-basis: 45%;
  }
}
