input[type='checkbox'].Commercial-checkbox {
  opacity: 0;
  width: 0;
}

input[type='checkbox'].Commercial-checkbox + label {
  font-family: var(--fontMedium);
  margin: 0;
  clear: none;
  color: var(--bodyText-light);
  padding: 5px 0 4px 40px;
  cursor: pointer;
  background: url('../../static/assets/commercial/images/icon-valid.svg')
    no-repeat left center / 20px 20px;
}

input[type='checkbox']:checked.Commercial-checkbox + label {
  background-image: url('../../static/assets/commercial/images/icon-error.png');
  font-family: var(--fontUltra);
  color: var(--bodyText-dark);
}

input[type='checkbox'].Commercial-checkbox:hover + label {
  color: var(--bodyText-dark);
}

input[type='checkbox'].Commercial-checkbox-V2 + label {
  background: url('../../static/assets/commercial/images/icon-checkbox-empty.svg')
    no-repeat left center / 20px 20px;
}
input[type='checkbox']:checked.Commercial-checkbox-V2 + label {
  background-image: url('../../static/assets/commercial/images/icon-checkbox-checked.svg');
}
input[type='checkbox'].Commercial-checkbox-V2:disabled + label {
  background: url('../../static/assets/commercial/images/icon-checkbox-disabled.svg')
    no-repeat left center / 20px 20px;
}
