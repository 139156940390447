.AlertsWidget-header {
  background: var(--backgroundColor-dark);
  padding: 20px 20px 0;
  position: relative;
}

.AlertsWidget-header-heading h1 {
  margin: 0;
}

.AlertsWidget-header-heading,
.AlertsWidget-header-labels {
  display: inline-block;
}

.AlertsWidget-header-labels {
  font-family: var(--fontBold);
  position: absolute;
  right: 20px;
  margin-top: 5px;
}

.AlertsWidget-header-labels label:first-of-type {
  margin-right: 15px;
}

.AlertsWidget-header-labels label span {
  margin-left: 6px;
}

.AlertsWidget-alert-status-icon {
  width: 12px;
  height: 12px;
}

.AlertsWidget-tabview {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  margin-top: 24px;
}

.AlertsWidget-tabs {
  background: transparent;
  padding: 0;
  margin: 0;
  box-shadow: none;
  display: inherit;
  justify-content: space-between;
}

.AlertsWidget-tab button {
  background: none;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 14px;
  color: var(--bodyText-light);
}

.AlertsWidget-alertlist {
  padding: 20px 20px 0;
  overflow-y: scroll;
  height: 100%;
  max-height: 927px;
}

.AlertsWidget-alert-row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px var(--backgroundColor-dark);
  color: #6a6a6a;
  position: relative;
  cursor: pointer;
}

.AlertsWidget-alert-row:last-of-type {
  border: none;
}

.AlertsWidget-alert-row .AlertsWidget-alert-status-icon {
  position: absolute;
  right: 0;
  top: 0;
}

.AlertsWidget-alert-site {
  font-family: var(--fontBold);
  margin: 0 0 10px;
  transition: color ease-in-out 0.15s;
}

.AlertsWidget-alert-row:hover .AlertsWidget-alert-site {
  color: var(--primaryColorDark);
}

.AlertsWidget-alert-title {
  font-family: var(--fontBold);
  color: #262626;
  margin: 0 0 10px;
}

.AlertsWidget-btn-all-alerts {
  margin: 20px auto;
  text-align: center;
}

.AlertsWidget-modal {
  text-align: center;
}

.AlertsWidget-modal-message-icon {
  width: 20px;
  height: 20px;
  fill: transparent;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 3px;
}

.AlertsWidget-modal-message p {
  display: inline-block;
}

.AlertsWidget-modal-message small {
  font-family: var(--fontUltra);
  display: block;
}
