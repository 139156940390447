.heading_section {
  margin-bottom: var(--gutterSpacing);
}

.action {
  margin-top: var(--gutterSpacing);
  text-align: right;
}

.action_reset {
  margin-right: 10px;
}
