.row {
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 0;
  align-items: baseline;
}

.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}

.label {
  font-family: var(--fontUltra);
}

.label span {
  font-family: var(--fontMedium);
}

.action {
  margin-top: var(--gutterSpacing);
  display: flex;
  align-content: baseline;
  width: 100%;
  justify-content: space-between;
}

.link {
  margin: 10px 0 0 auto;
  text-align: center;
}

.link_icon {
  position: relative;
  top: 2px;
  left: 0;
  margin-left: 3px;
  transition: all ease-in-out 0.15s;
}

.link:hover .link_icon {
  left: 3px;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
  margin-bottom: 1rem;
}

.action {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.action > * {
  margin-bottom: 10px;
}

.action > *:last-child {
  margin-bottom: 0;
}

.action_controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.card_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}

.label_wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.active_days {
  color: var(--magenta);
}

.days {
  padding: 0.18rem;
}

.product {
  font: 0.875rem var(--fontNeoRegular);
  padding-top: 0.2rem;
  color: var(--subTitleColor);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display_name {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.role_name {
  font: 0.875rem var(--fontNeoRegular);
  padding-top: 0.2rem;
  color: var(--subTitleColor);
}

.invited_member {
  width: fit-content;
  background: var(--colorWarning);
}

.avatar {
  height: 3rem;
  width: 3rem;
  background: #fff;
  color: #e20074;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.25rem;
  margin-right: 0.5rem;
  background: #e8e8e8;
}

.avatar_img {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
}

.action_button svg path {
  fill: var(--magenta);
}

.action_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.avatar_wrapper {
  position: relative;
}

@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}

@media (max-width: 767px) {
  .card_header {
    align-items: flex-start;
    gap: 1.5rem;
  }

  .card_title {
    width: 100%;
  }
}
