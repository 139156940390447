.input_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mode_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.6rem;
}

.file_input {
  flex: 1 1;
  padding: 0.5rem;
  background: #fff;
  border-radius: var(--borderRadius);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.header th,
.header td {
  font-family: var(--fontUltra);
  text-transform: uppercase;
  border: solid 1px var(--backgroundColor-dark);
  cursor: pointer;
}

.header:hover {
  background-color: var(--backgroundColor);
}

.column th,
.column td {
  padding: 0.6rem;
  border: solid 1px var(--backgroundColor-dark);
}

.header {
  font-family: var(--fontUltra);
}

.item:nth-child(odd) {
  background-color: var(--backgroundColor);
}

.table.border-lite .header,
.table.border-lite .column,
.table.border-lite .item {
  border: none;
  border-bottom: solid 1px var(--backgroundColor-dark);
  background: transparent;
  padding: var(--gutterSpacing) 0.6rem;
  text-align: left;
  font-family: var(--fontMedium);
}

.table.border-lite .item:last-of-type,
.table.border-lite .item:last-of-type .column {
  border: none;
}
.error_message {
  margin: 1rem 0;
  color: #fff;
  background: var(--colorDanger);
  padding: 0.5rem;
}
.action_button {
  margin-bottom: 0.7rem;
  width: 100%;
  padding: 8px 10px;
}
.action_button:last-child {
  margin-bottom: 0;
}
.slide_container {
  margin-bottom: var(--gutterSpacing);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.button_wrapper {
  margin-top: auto;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.button_wrapper .action:last-child {
  display: flex;
  gap: 1rem;
}
.upload_faq_container label {
  padding: 1rem 0;
  display: block;
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}

.upload_faq_container .upload_content .label {
  padding: 0;
  cursor: pointer;
  display: block;
}
.upload_faq_container .file_input {
  display: none;
}
.input_container .label {
  border-radius: var(--borderRadius);
  outline: none;
  width: 100%;
  background: #fff;
  padding: 1rem;
  font: 1rem var(--fontNeoBold);
  color: var(--headingColor);
  border: 0.12rem var(--inputBackgroundColor);
  background-color: var(--inputBackgroundColor);
  cursor: pointer;
}

.upload_faq_container .upload_placeholder {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

.subtitle {
  font: 0.8rem var(--fontNeoThin);
  color: var(--pageBorderColor);
  margin-top: 0.2rem;
}
.button_icon {
  width: 24px;
  height: 24px;
}
@media (max-width: 1400px) {
  .upload_faq_btn {
    font-size: 0.9rem;
  }
}
