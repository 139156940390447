.container {
  margin-top: var(--gutterSpacing);
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.users {
  flex-basis: var(--columnThird);
  margin-bottom: 2%;
}

.header,
.header_vb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_action {
  display: flex;
  flex-basis: 250px;
}

.loading {
  height: 20px;
}

.pagination {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin-top: 10px;
  padding: var(--gutterSpacing);
}
.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}
.header,
.header_vb {
  width: 100%;
  padding-left: 1rem;
  padding-bottom: 0;
}

.toggle {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }

  .users {
    flex-basis: var(--columnHalf);
  }
}
.map {
  height: 100%;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
  }
  .header {
    align-items: flex-start;
    margin-top: 1.5rem;
  }
  .header_vb {
    justify-content: space-between;
    margin-top: 1.5rem;
    align-items: flex-start;
  }
}

.message {
  font: 1rem var(--fontNeoBold);
  min-height: 5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}
