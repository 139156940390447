.container {
  background: #fff;
  min-height: 100vh;
}

@media (max-width: 660px) {
  .content_container {
    margin-left: 0;
  }
}
