.container {
  position: relative;
  background-color: var(--bodyBackgroundColor);
}
.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}
.list_table {
  white-space: nowrap;
}

.button {
  justify-content: center;
}

.header {
  margin-top: 1rem;
}

@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-2);
  }
}
.message {
  font: 1rem var(--fontNeoBold);
  text-align: center;
}
