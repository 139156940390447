.container {
  display: flex;
}

.main_panel {
  flex: 4;
  width: 100%;
}

.left_panel {
  margin-right: var(--gutterSpacing);
}

.filters_title {
  margin: 40px 0 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px var(--backgroundColor-dark);
}

.add_container {
  width: 100%;
}

.add {
  display: flex;
  justify-content: flex-end;
}
