.container {
  display: flex;
  flex: 0 1 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: var(--gutterSpacing);
}

.heading {
  text-align: center;
  font: 1.5rem 'Tele-Neo-Bold' !important;
  margin-bottom: 0;
}

.message {
  text-align: center;
  font: 1rem 'Tele-Neo-Regular';
}

.no_data_img svg {
  height: 10rem;
}
