.imei {
  color: var(--bodyText-dark);
  cursor: pointer;
}

.imei:hover {
  color: var(--primaryColor);
}

.column_container {
  display: flex;
  flex-direction: row;
}

.column {
  flex: 0 1 50%;
  flex-direction: column;
}

.row {
  padding: 5px 0;
  display: flex;
  align-items: center;
  color: var(--bodyText-dark);
}

.row label {
  font-family: var(--fontUltra);
}

.row label span {
  font-family: var(--fontMedium);
}

.accordion_title {
  margin-bottom: var(--gutterSpacing);
}

.accordion_row {
  border-top: solid 1px var(--backgroundColor-dark);
  border-bottom: solid 1px var(--backgroundColor-dark);
  margin: -1px auto 0;
}
