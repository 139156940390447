.custom_datepicker {
  background-color: #fff;
  padding: 0;
  width: 100%;
  border: 0;
}
.custom_step_btn {
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.button_wrapper {
  margin-top: auto;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.container {
  margin-bottom: var(--gutterSpacing);
  display: flex;
  flex-direction: column;
  height: 100%;
}
