.container {
  width: 100%;
}
.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}
@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-2);
  }
}
.message {
  font: 1rem var(--fontNeoBold);
  text-align: center;
}
.table_action {
  display: flex;
  min-width: 5rem;
  justify-content: space-between;
}
.action_column {
  width: 3rem;
}
.raw_object_tooltip::after {
  width: 6rem;
}
.title_column {
  min-width: 6rem;
}
.thing_column {
  min-width: 10rem;
}
.reward_column {
  min-width: 10rem;
}
.message_column {
  min-width: 7rem;
}
