.heading {
  margin: 0 0 var(--gutterSpacing) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.action_wrapper .action_button:first-child {
  margin-bottom: 0.7rem;
}
.action_button_dual {
  width: 100%;
  margin-bottom: 0.7rem;
}

.action_wrapper .action_button {
  width: 100%;
}
.faq_not_found {
  display: block;
  text-align: center;
  position: relative;
}
.dropDown_index {
  z-index: 1000;
}

.demo_del_btn {
  width: 100%;
  margin-top: 0.7rem;
  padding: 8px 10px;
}
.btn_wrapper {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.container {
  background: var(--bodyBackgroundColor);
}
.btn_wrapper .action_button {
  display: flex;
  align-items: center;
}
.btn_order svg path {
  fill: #fff;
}
@media (max-width: 1400px) {
  .btn_wrapper .action_button,
  .btn_order {
    font-size: 0.8rem;
  }
}

@media (max-width: 420px) {
  .btn_wrapper .action_button:nth-child(2) {
    flex-grow: 1;
    width: 100%;
  }
  .btn_wrapper .action_button {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .action_button.add_faq_btn {
    font-size: 1.2rem;
  }
}
