.description {
  background-color: var(--backgroundColor);
  padding: 1rem;
  width: 100%;
  margin: 0;
}
.faq_description {
  font: 1.12rem/1.3 var(--fontRegular);
  background-color: transparent;
  margin: 0;
  color: var(--headingColor);
  padding: 1rem;
}
.details_wrapper {
  display: flex;
  align-items: self-start;
}
.edit_button {
  margin-right: 0.5rem;
  border: 0;
  box-shadow: none;
  padding: 0.2rem;
  font-size: 1.21rem;
  color: #9f9f9f;
}
.edit_button:hover {
  border: 0;
  box-shadow: none;
}
.details_wrapper a {
  text-decoration: underline;
  color: var(--magenta);
}
.container {
  margin-bottom: 1rem;
  background: var(--faqContentBackground);
}
