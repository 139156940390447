.container {
  margin-bottom: 2%;
  flex: 0 1 49%;
  transition: box-shadow 150ms linear, background-color 150ms linear;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap);
}

.action {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.action > * {
  margin-bottom: 10px;
}

.action > *:last-child {
  margin-bottom: 0;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

.status_pill {
  border-radius: 0.2rem;
  padding: 0.4rem;
  font: 0.8rem var(--fontNeoMedium);
}

.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.card_title {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.status {
  margin: 0.2rem 0 0.6rem;
}

.device_id {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action_controls {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}

.separator {
  border-bottom: 1px solid #cccccc;
  margin: 1.5rem 0;
}

.footer_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer_action svg path {
  fill: var(--magenta);
}

@media (max-width: 767px) {
  .action_controls {
    flex-direction: column;
  }

  .device_id {
    overflow: visible;
    white-space: initial;
  }
}
