.input_container {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.input_container_blob {
  padding-top: 1rem;
}

.mode_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file_input {
  flex: 1;
  width: 100%;
  background: #fff;
  border-radius: var(--borderRadius);
}

.media_asset_upload .input_container .label {
  border-radius: var(--borderRadius);
  outline: none;
  width: 100%;
  background: #fff;
  padding: 1rem;
  font: 1rem var(--fontNeoBold);
  color: var(--headingColor);
  border: 0.12rem var(--inputBackgroundColor);
  background-color: var(--inputBackgroundColor);
  cursor: pointer;
}
.media_asset_upload .upload_placeholder {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}
.media_asset_upload .file_input {
  display: none;
}
.subtitle {
  font: 0.8rem var(--fontNeoThin);
  color: var(--pageBorderColor);
  margin-top: 0.2rem;
}
.slide_container {
  margin-bottom: var(--gutterSpacing);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.button_wrapper {
  margin-top: auto;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.button_wrapper .action:last-child {
  align-self: flex-end;
}
.upload_media .label,
.media_asset_upload label {
  padding: 1rem 0;
  display: block;
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}

.media_asset_upload .upload_content .label {
  padding: 0;
  cursor: pointer;
  display: block;
}

.filename {
  padding: 1rem 0;
  display: block;
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}
