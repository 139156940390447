.container {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.events {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}

@media (max-width: 767px) {
  .events {
    grid-template-columns: var(--grid-columns-1);
  }
}

.heading_label {
  display: block;
  font: normal 18px/1 var(--fontBold);
  margin: var(--gutterSpacing) 0;
}

.select_margin {
  margin: 8px 0 10px;
  width: 100%;
}

.execute {
  align-self: end;
  margin-top: calc(var(--gutterSpacing) / 2);
}

.eventType {
  margin-bottom: 20px;
}

.eventType label {
  margin: 0 20px 0 0;
}

.command_container {
  width: 40%;
}

.action_button {
  width: 100%;
  flex: 1;
}

.scrollable_content {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  padding-bottom: 0.4rem;
}
