.container {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  justify-content: flex-start;
  min-height: 23px;
}

.inline {
  display: inline-block;
}

.label {
  margin-right: 10px;
  padding-bottom: 5px;
  text-align: left;
}

.input {
  flex-grow: 1;
}
