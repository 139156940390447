.container {
  cursor: pointer;
  padding: 0.75rem 1rem;
  text-align: center;
  border: none;
  color: #555;
  font-family: var(--fontUltra);
  font-size: 1rem;
  text-decoration: none;
  border-radius: var(--borderRadius);
  transition: all ease-in-out 0.1s;
}

.container:hover {
  background-color: var(--backgroundColor);
}

.selected {
  color: #fff;
  background: var(--primaryColor);
  box-shadow: var(--shadowMedium);
}

.selected:hover {
  background: var(--primaryColor);
}
