.container {
  display: flex;
  align-items: center;
  font-family: var(--fontBold);
  margin: 0 auto var(--gutterSpacing);
  padding: calc(var(--gutterSpacing) / 2);
  border-radius: var(--borderRadius);
  color: var(--bodyText-dark);
  border: solid 1px transparent;
}

.message {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

@media (min-width: 680px) {
  .confirm {
    margin-left: var(--gutterSpacing);
  }
}
