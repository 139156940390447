.container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 5px 0;
}

.column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.label,
.title {
  font-family: var(--fontUltra);
}

.label::after,
.title::after {
  content: ':';
}

.list {
  margin: 0;
  padding-left: 15px;
}

.list_item {
  list-style-type: none;
  padding: 5px 0;
}

.time_display_wrapper .list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 0;
}

.time_display_wrapper .list_item {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.time_display_wrapper .title {
  font: 1rem var(--fontNeoBold);
  margin-bottom: 1rem;
}

.time_display_wrapper .label {
  font: 1rem var(--fontNeoMedium);
}

.time_display_wrapper .label_item {
  font: 1rem var(--fontNeoBold);
}

.time_display_wrapper .label::after,
.time_display_wrapper .title::after {
  content: initial;
}
