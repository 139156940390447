.card_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  gap: 1rem;
}
.avatar_container {
  width: 3rem;
  height: 3rem;
}

.avatar {
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.4rem var(--fontNeoUltra);
  color: var(--primaryColor);
}
.avatar_bg {
  background: var(--profileBackgrondColor);
}
.user_info {
  display: flex;
  flex-direction: column;
}
.user_info .name {
  font: 1.3rem var(--fontNeoMedium);
}
.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--column-gap);
}
.footer_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
}

@media (max-width: 767px) {
  .footer_action {
    justify-content: center;
  }
}
