.container {
  width: 100%;
}

.heading {
  padding-bottom: 40px;
}

.back {
  all: unset;
  cursor: pointer;
  display: block;
  margin-bottom: var(--gutterSpacing);
}

.back svg {
  position: relative;
  top: 2px;
}

.back:hover {
  color: var(--primaryColor);
}

.back_icon {
  align-self: flex-end;
  font-size: 14px;
}

.heading_label {
  font-family: var(--fontUltra);
  font-size: 18px;
  padding-bottom: var(--gutterSpacing);
}

.label_lastupdated {
  margin-left: 50px;
}

.heading_label_margin {
  margin-left: 5px;
}

.sensors_container {
  display: flex;
  flex-flow: column;
}

.sensors_row {
  border-top: solid 1px var(--backgroundColor-dark);
}

.row {
  display: flex;
  flex: 3;
  cursor: pointer;
  flex-wrap: wrap;
}

.title,
.status {
  flex: 1;
  font-family: var(--fontBold);
  cursor: pointer;
}

.status span {
  font-family: var(--fontRegular);
}
