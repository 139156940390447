.confirm {
  display: flex;
  margin: 10px 0;
}

.confirm_input {
  flex: 1;
}

.button_container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
