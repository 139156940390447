.container {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.items {
  display: grid;
  grid-template-columns: var(--grid-columns-4);
  grid-gap: var(--gutterSpacing);
}
.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--column-gap);
}

.heading {
  color: var(--headingColor);
  font: 1.5rem var(--fontNeoMedium);
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iot_header .heading {
  font: 1.25rem var(--fontNeoMedium);
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.items {
  flex: 45% 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: var(--headingColor);
  font: 1rem/1.3 var(--fontNeoMedium);
  text-transform: capitalize;
}

.row .items span {
  text-transform: capitalize;
  font: 1rem/1.3 var(--fontNeoBold);
}

.grid4 .items {
  flex-basis: 20%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step_count_wrapper {
  margin-top: 1rem;
}

.notifications_wrapper .items .labels {
  margin-bottom: 0.5rem;
}

.notifications_wrapper .items .value {
  padding-top: 0.3rem;
}

.notifications_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.items:first-child,
.items:last-child {
  flex-basis: 100%;
}
.object_button {
  display: block;
}

.object_button > button {
  padding-left: 0;
}
@media (max-width: 767px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}
