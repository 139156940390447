.container {
  margin-top: var(--gutterSpacing);
}

.heading_label {
  font: normal 18px/1 var(--fontBold);
  padding-bottom: var(--gutterSpacing);
}

.back {
  all: unset;
  cursor: pointer;
  display: block;
  margin-bottom: var(--gutterSpacing);
}

.angle_icon {
  position: relative;
  top: 2px;
  font-size: 14px;
}
