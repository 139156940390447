.delete_btn {
  font: 0.8rem var(--fontBold);
  color: #fff;
  padding: 0.5rem 1.25rem;
  border: none;
  text-transform: uppercase;
}
.spinner {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  width: calc(100vw + var(--leftNavWidth));
  z-index: 999;
}
.spinner_deleting {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  background: #fff;
  height: 100%;
  z-index: 2;
}
