.container {
  background: #fff;
  padding: var(--gutterSpacing);
  box-shadow: var(--shadow);
}

.button_container {
  margin: var(--gutterSpacing) 10px 0 0;
  display: flex;
  justify-content: flex-end;
}
