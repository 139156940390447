.vote_text {
  color: #2d809a;
  font-size: 1.12rem;
}
.voting_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.voting_wrapper li {
  list-style-type: none;
  padding-left: 1.5rem;
}
.voting_wrapper li:first-child {
  padding-left: 0;
}
.voting_wrapper li {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.vote_section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.faq_vote_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.number_of_votes {
  padding-right: 0.5rem;
  font: 1.25rem/1 var(--fontBold);
}
.edit_btn {
  font: 0.8rem var(--fontBold);
  padding: 0.5rem 1.25rem;
  border: none;
  text-transform: uppercase;
}
.modal_wrapper {
  display: flex;
  gap: 1rem;
}
.no_cursor li {
  cursor: auto;
}

.demo_del_btn {
  font: 0.8rem var(--fontBold);
  padding: 0.5rem 1.25rem;
  border: none;
  text-transform: uppercase;
}
.thumbs_down_icon svg path:first-child {
  fill: #e8200d;
}
.thumbs_up_icon svg path:first-child {
  fill: #078a14;
}

.faq_voting_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .faq_vote_section {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: flex-start;
    margin-top: 1rem;
  }
  .modal_wrapper {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
  }
  .demo_del_btn {
    margin: 0;
  }
  .faq_voting_wrapper {
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .modal_wrapper {
    gap: 0.5rem;
    margin-bottom: 0;
  }
}
