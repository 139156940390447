.remove_message_heading {
  margin-bottom: var(--gutterSpacing);
}

.row {
  display: flex;
  flex-direction: column;
}

.row label {
  font-family: var(--fontUltra);
}

.row span {
  font-family: var(--fontMedium);
}

.imei {
  margin: 0;
  padding: 5px 0 5px 30px;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor-dark);
  max-height: 100px;
  overflow: scroll;
  font-family: var(--fontMedium);
}

.imei li {
  padding: 4px 0;
}
