.row {
  display: flex;
  padding: 10px 0 0 0;
  align-items: baseline;
}

.label {
  font-family: var(--fontUltra);
}

.label span {
  font-family: var(--fontMedium);
}

.action {
  display: flex;
  align-content: baseline;
}

.link {
  margin: 10px 0 0 auto;
  text-align: center;
}

.link_icon {
  position: relative;
  top: 2px;
  left: 0;
  margin-left: 3px;
  transition: all ease-in-out 0.15s;
}

.link:hover .link_icon {
  left: 3px;
}

.avatar_container {
  width: 86px;
  height: 86px;
}

.avatar {
  background-size: cover;
  width: 100%;
  height: 100%;
  border: solid 6px #fff;
  box-shadow: var(--shadow);
  display: inline-block;
  border-radius: 50%;
}
