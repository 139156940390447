.container {
  position: relative;
}

.map {
  height: 100%;
  border-radius: 8px;
}

.message {
  font: 1rem var(--fontNeoBold);
  text-align: center;
}
.header {
  margin-top: 1rem;
}
