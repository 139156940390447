.tabs {
  display: flex;
  align-items: center;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: var(--gutterSpacing);
  border-bottom: 1px solid #8c8c8c;
}

.label {
  font: 1rem var(--fontNeoMedium);
}

.heading_deleted_things {
  font: normal 0.7rem/1 var(--fontNeoRegular);
  margin: 10px 0 0;
}

.thing_number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0.4rem;
}

.button_icon {
  position: relative;
  top: 3px;
  margin-right: 3px;
}

.no_things {
  margin-top: var(--gutterSpacing);
}

.add_thing {
  white-space: nowrap;
}

.thing_details {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex: 45% 1;
  align-items: flex-start;
}

.custom_dropdown :nth-child(2) {
  max-height: 400px;
  overflow-y: auto;
}

.thing_select {
  display: flex;
  flex: 24% 1;
  color: var(--headingColor);
}

.subtitle {
  font: 0.875rem var(--fontNeoMedium);
}

.thing_actions {
  display: flex;
  flex: 45% 1;
  justify-content: flex-end;
  gap: 1.5rem;
}

@media (max-width: 767px) {
  .thing_details {
    flex-direction: column;
    align-items: normal;
    width: 100%;
    margin-top: 1.5rem;
  }
  .thing_actions {
    display: flex;
    flex: 45% 1;
    justify-content: flex-end;
    gap: 1.5rem;
  }
  .heading {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
