.thing_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.container_tab {
  display: flex;
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.column:last-of-type {
  margin-right: 0;
}

.heading_row {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-direction: row;
  padding-top: 1.5rem;
  margin-bottom: var(--gutterSpacing);
}

.heading {
  font: 1.5rem var(--fontNeoBold);
}

.add_thing {
  margin-left: auto;
}

.action {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}
.heading_with_image {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.avatar_container {
  width: 4rem;
  height: 4rem;
}

.avatar {
  background-size: cover;
  width: 100%;
  height: 100%;
  border: solid 3px #e1e1e1;
  display: inline-block;
  border-radius: 50%;
}

.row {
  display: flex;
  flex-direction: row;
}

.items {
  display: flex;
  flex-direction: column;
  flex-basis: 23%;
}

.label {
  font: 1rem var(--fontNeoMedium);
}

.value {
  font: 1rem var(--fontNeoBold);
}

.thing_details_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--pageBorderColor);
  padding-bottom: 1.5rem;
  gap: 1.5rem;
}

.status_mark span {
  height: 0.6rem;
  width: 0.6rem;
  margin-left: 0.5rem;
}

.text {
  font: 1rem var(--fontNeoBold);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--headingColor);
}

@media (max-width: 960px) {
  .container_tab,
  .section,
  .column {
    flex-direction: column;
    margin: 0;
  }
  .column {
    padding: 0 0 var(--gutterSpacing) 0;
  }
}

@media (max-width: 660px) {
  .container_tab {
    grid-template-columns: var(--grid-columns-1);
  }
}
@media (max-width: 767px) {
  .heading_row {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}
