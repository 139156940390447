.heading {
  font: 1.4rem var(--fontNeoMedium);
}

.form_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.user {
  display: flex;
  flex: 45% 1;
}

.text_area {
  height: 6rem;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

@media (max-width: 767px) {
  .user_container {
    flex-direction: column;
    flex-basis: 100%;
  }

  .user {
    width: 100%;
  }

  .action {
    justify-content: space-between;
  }
}
