.heading {
  margin: 0 0 var(--gutterSpacing) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.action_wrapper .action_button:first-child {
  margin-bottom: 0.7rem;
}
.action_button_dual {
  width: 100%;
  margin-bottom: 0.7rem;
}

.action_wrapper .action_button {
  width: 100%;
}
.faq_not_found {
  display: block;
  text-align: center;
  position: relative;
}
.dropDown_index {
  z-index: 1000;
}

.demo_del_btn {
  width: 100%;
  margin-top: 0.7rem;
  padding: 8px 10px;
}
.help_btn {
  text-transform: capitalize;
  font: 1rem var(--fontNeoRegular);
  padding: 0;
  display: flex;
  align-items: center;
}
.container {
  padding: 0;
}

@media (max-width: 767px) {
  .help_text {
    display: none;
  }
}
