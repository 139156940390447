.row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--gutterSpacing);
  min-height: 200px;
  border-radius: var(--borderRadius);
  transition: background-color 0.2s;
}

.column {
  border: solid 1px var(--backgroundColor-dark);
  border-radius: var(--borderRadius);
  padding: var(--gutterSpacing);
  margin-right: var(--gutterSpacing);
  position: relative;
  flex: 1;
}

.column:last-of-type {
  margin-right: 0;
}

.title {
  font: normal 24px/1 var(--fontUltra);
  margin-right: 10px;
}

.label {
  text-align: center;
  font: normal 24px/1 var(--fontUltra);
  color: #7cb5ec;
}

@media (max-width: 660px) {
  .column:first-of-type {
    margin-bottom: var(--gutterSpacing);
  }
  .column {
    margin-right: 0;
  }
}
