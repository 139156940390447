.theme-commercial {
    /*-- Commercial Tracker -- */
    --leftNavWidth: 92px;
    --headerHeight: 66px;
    --primaryColor: #346dcd;
    --primaryColor-hover: var(--primaryColorDark);
    --backgroundColor: #fff;
    --backgroundColor-dark: #f2f2f2;
    --primaryColorDark: #1b3970;
    --bodyText-light: #757575;
    --gutterSpacing: 32px;
}
