.select {
  border: solid 1px var(--inputBorderColor);
  padding: 12px 20px 12px 8px;
  font-family: var(--fontMedium);
  font-size: 14px;
  letter-spacing: -0.02em;
  background: url('../../static/assets/dropdown-chevron.png') no-repeat right
    5px center / 10px #fff;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  border-radius: var(--borderRadius);
  -webkit-appearance: none;
}
