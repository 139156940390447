.container {
  display: flex;
  flex-direction: column;
}

.title {
  position: sticky;
  top: 0;
  width: 100%;
  background: #fff;
  padding: var(--gutterSpacing) 0;
}

.items {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.add {
  display: flex;
  margin: var(--gutterSpacing) auto;
}

.input {
  flex: 1;
}
