.avatar_delete {
  position: absolute;
  top: 1.7rem;
  right: 0.2rem;
}
.avatar_delete .button {
  padding: 4px;
}
.avatar_delete .button svg {
  height: 0.8rem;
  width: 0.8rem;
}
