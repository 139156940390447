.container {
  display: flex;
  flex-direction: column;
}
.status_pill {
  border-radius: 0.2rem;
  padding: 0.4rem;
  font: 0.8rem var(--fontNeoMedium);
}
.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.card_title {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.status {
  margin: 0.2rem 0 0.6rem;
  display: flex;
  align-items: center;
}
.inactive_notice {
  color: var(--colorDanger);
  margin-left: 0.4rem;
}
.config_id {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action_controls {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}
.footer_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding-top: 1rem;
}
@media screen and (max-width: 767px) {
  .action_controls {
    flex-direction: column;
  }
}
