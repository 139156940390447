.form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  flex: 0 1 49%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filter_label {
  flex: 0 1 100%;
  font: normal 14px/1 var(--fontBold);
  margin-bottom: 5px;
}

.form_input {
  flex: 0 1 100%;
  min-height: 42px;
  margin-bottom: 2%;
}

.form_select {
  flex: 0 1 100%;
  height: auto;
}
