.text {
  margin-bottom: var(--gutterSpacing);
}

.add_device_container {
  text-align: right;
}

small {
  display: block;
  font-size: 0.7em;
}

small strong {
  font-style: italic;
}
