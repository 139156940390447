.scrollable_content {
  height: 100%;
  overflow: auto;
}
.button_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-top: solid 1px #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}
.inner_wrapper {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.export_btn {
  margin-bottom: 0;
}
.container {
  margin-bottom: var(--gutterSpacing);
  display: flex;
  flex-direction: column;
  height: 100%;
}
