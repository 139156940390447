.container {
  display: inline-block;
  border-radius: 50%;
  background: var(--backgroundColor-dark);
  vertical-align: middle;
  margin-left: 5px;
}

.sm {
  width: 4px;
  height: 4px;
}

.md {
  width: 6px;
  height: 6px;
}

.lg {
  width: 8px;
  height: 8px;
}

.active,
.true {
  background: var(--colorSuccess);
}

.inactive {
  background: var(--colorWarning);
}

.deleted,
.false {
  background: var(--colorDanger);
}

.info {
  background: var(--colorInfo);
}

.disabled {
  background: var(--colorDisabled);
  color: var(--bodyText-dark);
}
