.container {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.controls {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
}

.controls_device {
  margin: 0;
}

.left_panel {
  width: 220px;
  background-color: #e20074;
}

.right_panel {
  width: 100%;
}

.logo {
  margin-top: 38px;
  text-align: center;
}

.data_wrapper {
  display: flex;
}

.controls > *:not(:first-child) {
  margin-left: 15px;
}

.data_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  margin-top: var(--gutterSpacing);
}

.state {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.data {
  position: sticky;
  top: 0;
  flex: 1;
  margin-left: var(--gutterSpacing);
  max-height: 80vh;
}

.no_data {
  padding: 20px;
}

.loading_element {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 150px);
}

.env {
  text-decoration: underline;
  font-style: italic;
}

.link {
  color: var(--primaryColor);
  margin: 0 8px;
}

@media (max-width: 660px) {
  .controls {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
  .controls * {
    margin: 0 0 8px;
    width: 100%;
  }
  .controls > *:not(:first-child) {
    margin-left: 0;
  }
  .data {
    position: relative;
    margin: 0;
  }
}

@media (min-width: 767px) {
  .historical_state {
    max-width: 52vw;
  }
}
