.container {
  display: flex;
  flex-direction: column;
  font-size: var(--bodyFontSize);
}

.info_header_heading {
  margin-top: 0;
}

.row {
  padding: 5px 0;
}

.label {
  font-family: var(--fontBold);
}

.label_content {
  font-family: var(--fontMedium);
}

.gm-style .gm-style-iw-c {
  box-shadow: var(--shadowMedium);
}
