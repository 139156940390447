.container {
  margin: 1rem 0;
  padding-bottom: 1rem;
}

.nav_link {
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font: 1rem/1rem var(--fontNeoRegular);
  padding: 0.5rem;
  text-decoration: none;
  margin-bottom: 0.2rem;
  border-radius: var(--borderRadius-large);
}

.nav_link_active {
  color: #e20074;
}

.nav_link_active .menu_icon svg path {
  fill: #e20074;
}

.nav_link_active .nav_link_icon {
  display: block;
  position: relative;
  top: 1px;
  left: 3px;
}

.nav_link_icon {
  display: none;
  font-size: 14px;
}

.expandable_menu .nav_link {
  padding: 0.8rem;
}

.user {
  margin-top: 40px;
}

.expandable_menu .menu_icon {
  display: block;
}

.link_name {
  padding: 0 0.5rem 0;
}
