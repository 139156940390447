.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--gutterSpacing);
}

.users {
  flex-basis: var(--columnThird);
  margin-bottom: 2%;
}

.pagination {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin-top: 10px;
  padding: var(--gutterSpacing);
}

@media (max-width: 900px) {
  .users {
    flex-basis: var(--columnHalf);
  }
}

@media (max-width: 660px) {
  .users {
    flex-basis: var(--columnFull);
  }
}
