.container {
  position: relative;
}

.trigger {
  cursor: pointer;
  color: var(--primaryColor);
}

.trigger_icon {
  position: relative;
  top: 3px;
  left: 3px;
}

.container.expanded .content {
  height: 100%;
}

.label {
  font-family: var(--fontMedium);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.label_bold {
  font-family: var(--fontUltra);
}

.content_inner {
  background: rgba(0, 0, 0, 0.08);
  border-radius: var(--borderRadius-large);
  margin: 10px 0 3px;
  padding: 10px;
  font-size: 0.9em;
}

.content_inner p {
  margin: 0;
  padding: 5px 0;
}

.content_inner_heading {
  margin: 0 0 5px;
  padding-bottom: 8px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

/* -- Themes -- */
.Navigation-dropdown .content_inner ul,
.mobile-nav .content_inner ul {
  margin: 0;
  padding: 0;
  color: #fff;
}

.Navigation-dropdown .content_inner ul li,
.mobile-nav .content_inner ul li {
  margin: 0;
  list-style: none;
  line-height: initial;
  padding: 0 0 10px;
}

.Navigation-dropdown .content_inner ul li span,
.mobile-nav .content_inner ul li span {
  display: block;
  font-family: var(--fontUltra);
}

.Navigation-dropdown .content_inner ul li span small,
.mobile-nav .content_inner ul li span small {
  font-family: var(--fontMedium);
}

/* .container:hover .menu_icon svg path,
.container:hover .trigger {
  fill: #E20074;
  color: #E20074;
} */

.expanded .label {
  background: #4c4c4c;
  border-radius: 0.5rem;
  color: #fff;
}

.Navigation-dropdown {
  border-radius: var(--borderRadius-large);
  margin-bottom: 4px;
}

.Navigation-dropdown.expanded {
  /* background: rgba(255, 255, 255, 0.12); */
  border-radius: var(--borderRadius-large);
}

.no_bg span:hover,
.no_bg.trigger {
  background-color: transparent !important;
}

.no_bg {
  background-color: #262626;
}

.Navigation-dropdown .content {
  padding: 0 10px 10px;
}

.Navigation-dropdown .content_inner {
  padding: 8px;
  margin: 0;
  background: none;
  max-height: 100%;
}

.Navigation-dropdown .trigger_icon {
  font-size: 0.9em;
  position: relative;
  left: 8px;
}

.Navigation-dropdown-link {
  font: normal 14px/1 var(--fontUltra);
  margin-bottom: 4px;
  background: none;
  max-height: initial;
  padding: 8px;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  text-decoration: none;
}

.Navigation-dropdown-link:hover,
.Navigation-dropdown-link-active {
  background: rgba(0, 0, 0, 0.05);
}

.Navigation-dropdown .content {
  padding: 0;
}

.Navigation-dropdown .trigger {
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font: 1rem/1rem var(--fontNeoRegular);
  padding: 0 0.5rem 0;
  text-decoration: none;
  margin-bottom: 0.2rem;
  border-radius: var(--borderRadius-large);
}

/* .Navigation-dropdown .trigger:hover {
  color: #E20074;
} */

.expandable_menu_icon {
  position: absolute;
  right: 1rem;
}

.Navigation-dropdown .content_inner_heading {
  display: none;
}

.device_search .content_inner {
  background: #fff;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.Navigation-dropdown .content_collapsed_inner {
  padding: 8px 0;
}

@media (max-width: 991px) {
  .Navigation-dropdown.expanded {
    background: #000;
  }

  .Navigation-dropdown .trigger {
    color: #fff;
  }

  .Navigation-dropdown .content {
    min-width: 200px;
    background: none;
  }

  .Navigation-dropdown .content_inner {
    background: none;
  }

  .User-dropdown {
    top: 8px;
  }

  .User-dropdown.expanded {
    background: #fff;
  }

  .User-dropdown .trigger {
    color: #fff;
    padding-bottom: 10px;
  }

  .User-dropdown.expanded .trigger {
    color: var(--primaryColor);
  }
}
