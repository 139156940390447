.container {
  max-width: 100%;
  overflow-x: scroll;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.header {
  font-family: var(--fontUltra);
  text-transform: uppercase;
  border: solid 1px var(--backgroundColor-dark);
  text-align: center;
  cursor: pointer;
}

.header:hover {
  background-color: var(--backgroundColor);
}

.column {
  padding: 10px;
  border: solid 1px var(--backgroundColor-dark);
  text-align: center;
}

.header {
  font-family: var(--fontUltra);
}

.item:nth-child(odd) {
  background-color: var(--backgroundColor);
}

.sort_icon {
  position: relative;
  left: 10px;
  top: 2px;
}

.table.border-lite .header,
.table.border-lite .column,
.table.border-lite .item {
  border: none;
  border-bottom: solid 1px var(--backgroundColor-dark);
  background: transparent;
  padding: var(--gutterSpacing) 10px;
  text-align: left;
  font-family: var(--fontMedium);
}

.table.border-lite .item:last-of-type,
.table.border-lite .item:last-of-type .column {
  border: none;
}
