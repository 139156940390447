.category_wrapper {
  background-color: #ffffff;
  color: #6a6a6a;
  padding: 1rem 0;
  font: 1.25rem/1 var(--fontRegular);
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: solid 1px #757575;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0 1rem;
}

.input_wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.input_wrapper input[type='number'] {
  width: 4rem;
  cursor: pointer;
  appearance: none;
}

.input_wrapper input::-webkit-outer-spin-button,
.input_wrapper input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.input_wrapper svg {
  cursor: pointer;
  margin-left: 15px;
}

.sticky_tiles {
  color: var(--headingColor);
  font: 1.12rem var(--fontNeoBold);
  height: auto;
}

.accordion_row {
  font: 1.12rem var(--fontRegular);
  color: var(--faqText-dark);
  padding: 0.5rem 1rem 0.5rem 1rem;
  background: var(--accordionBackgroundColor);
}

.accordion_row:last-child {
  border-bottom: transparent;
}

.accordion_row .accordion_column {
  padding: 1rem;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px #00000033;
}

.accordion_content {
  background-color: #fff;
  padding: 0 0.75rem;
}

.heading {
  margin: 0 0 var(--gutterSpacing) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
  color: var(--bodyText-dark);
}

.edit_button {
  margin-right: 0.5rem;
  border: 0;
  box-shadow: none;
  padding: 0.2rem;
  font-size: 1.21rem;
  color: #9f9f9f;
}

.edit_button:hover {
  border: 0;
  box-shadow: none;
}
@media (max-width: 767px) {
  .category_wrapper {
    z-index: 1;
  }

  .sticky_tiles {
    color: var(--headingColor);
    font: 1.12rem var(--fontNeoBold);
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
