.sticky_tiles {
  background-color: var(--backgroundColor);
  color: #6a6a6a;
  padding: 1rem 1.8rem;
  position: sticky;
  top: 0;
  font: 1.25rem/1 var(--fontRegular);
  height: auto;
  position: -webkit-sticky;
  position: sticky;
}
.no_padding {
  padding: 0;
}
.accordion_row {
  font: 1.12rem var(--fontRegular);
  color: #4a4a4a;
  border-bottom: solid 1px #e8e8e8;
}
.accordion_row:last-child {
  border-bottom: transparent;
}
.accordion_row .accordion_column {
  padding: 1rem 2rem;
  background: transparent;
}
.accordion_content {
  background-color: #f8f8f8;
  padding: 0 0.75rem;
}
.content_description {
  padding: 1rem;
  background-color: var(--backgroundColor);
  width: 100%;
}
.content_description > :first-child {
  padding-top: 0;
  margin-top: 0;
}
.content_description > :last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.content_description ul,
.content_description ol {
  padding-left: 1rem;
}
