.CommercialLeftPanelNavigation-link {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 15px 10px;
  font-size: 0.85em;
  transition: background ease-in-out 0.15s;
}

.CommercialLeftPanelNavigation-link:hover {
  background: var(--primaryColor);
}
.CommercialLeftPanelNavigation-link-active {
  background: var(--primaryColor);
  box-shadow: inset 6px 0 0 0 #fff;
}

.CommercialLeftPanelNavigation-link-icon {
  width: 24px;
  height: 24px;
  margin: 0 auto 8px;
  fill: transparent;
}

.CommercialLeftPanelNavigation-link-icon.icon-user {
  width: 16px;
}
