.error {
  color: var(--headingColor);
  font: 1rem var(--fontNeoBold);
  text-align: center;
}
.assign_config_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  width: 100%;
}
.config_button {
  font: 1rem var(--fontNeoBold);
  margin: 0 auto;
}
.config_button svg path {
  fill: var(--magenta);
}
.spinner {
  height: 150px;
  width: 150px;
  margin: 0 auto;
  display: block;
}
.assign_config_wrapper label {
  padding-bottom: 0.4rem;
}
.scrollable_content,
.add_config_wrapper {
  height: 100%;
  overflow: auto;
}
.button_wrapper {
  margin-top: auto;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.container {
  margin-bottom: var(--gutterSpacing);
  display: flex;
  flex-direction: column;
  height: 100%;
}
