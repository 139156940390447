.succeeded,
.active {
  background: rgba(40, 167, 69, 0.1);
  border: solid 1px rgba(40, 167, 69, 0.25);
}
.in_progress {
  background: rgba(255, 193, 7, 0.1);
  border: solid 1px rgba(255, 193, 7, 0.25);
}
.failed,
.deleted {
  background: rgba(220, 53, 69, 0.1);
  border: solid 1px rgba(220, 53, 69, 0.25);
}
