.pagination_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.page_size {
  width: 13rem;
}
.custom_pagination li {
  padding: 0.5rem 0.8rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pagination_wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--column-gap);
  }
  .page_size {
    width: 100%;
  }
  .custom_pagination {
    margin: 0px auto;
  }
}
