.container {
  display: flex;
  flex: 0 1 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.heading {
  text-align: center;
}

.message {
  text-align: center;
}
