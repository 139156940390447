.info_header {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.info_header_heading {
  margin: 5px 0;
}

.gm-style .gm-style-iw-c {
  box-shadow: var(--shadowMedium);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.item_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.label {
  font: 1rem var(--fontNeoMedium);
}

.value {
  font: 1rem var(--fontNeoBold);
}
