.statusText {
  margin-left: -5px;
}
.subItem {
  margin-left: 10px;
}

.time_display {
  display: block;
  margin-top: -10px;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-basis: 23%;
  gap: 0.4rem;
  word-break: break-word;
}
.label {
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}
.value {
  font: 1rem var(--fontNeoBold);
  margin-left: 0;
}

.sub_row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  padding-bottom: 1.5rem;
}
.column_1 {
  flex-basis: 100%;
}
@media (max-width: 767px) {
  .column_2,
  .column_3,
  .column_4 {
    flex-basis: 100%;
  }
}
@media (min-width: 768px) {
  .column_2,
  .column_3,
  .column_4 {
    flex-basis: 45%;
  }
}
@media (min-width: 1366px) {
  .column_1 {
    flex-basis: 100%;
  }
  .column_2 {
    flex-basis: 45%;
  }
  .column_3 {
    flex-basis: 30%;
  }
  .column_4 {
    flex-basis: 23%;
  }
}
