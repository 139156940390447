.container {
  margin-bottom: var(--gutterSpacing);
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.image {
  height: 50px;
  width: 27px;
}

.title {
  margin: 0 20px;
}

.count {
  flex: 1;
}
