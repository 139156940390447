.AddPackForBulk-container {
  width: 45%;
  /* box-shadow: 0 0 0 1px var(--backgroundColor-dark); */
  margin: 0 20px var(--gutterSpacing) 0;
}
.AddPackForBulk-main {
  margin-left: 100px;
}
.AddPackForBulk-header {
  background: var(--backgroundColor);
  padding: 10px 30px 10px 30px;
  position: relative;
}

.AddPackForBulk-body {
  padding: 30px 125px 30px 30px;
}

.AddPackForBulk-devices-header {
  display: flex;
  justify-content: space-between;
}

.AddPackForBulk-select-device {
  padding: 10px 30px 10px;
}

.Devices-site-dropdown {
  margin-top: 10px;
}

.AddPackForBulk-input {
  font-size: 16px;
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid grey;
}

.AddPackForBulk-label {
  font-size: 14px;
  color: grey;
}

.AddPackForBulk-add-div {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.AddPackForBulk-add-later {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
