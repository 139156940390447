.container {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row {
  text-align: center;
}

.input {
  border-radius: var(--borderRadius);
  outline: none;
  width: 100%;
  background: #fff;
  padding: 0.75rem;
  font: 1rem var(--fontNeoRegular);
  color: #8c8c8c;
  border: 0.12rem var(--inputBackgroundColor);
  background-color: var(--inputBackgroundColor);
}

@media (max-width: 660px) {
  .container {
    min-width: 100%;
  }
}
.button_wrapper {
  margin-top: auto;
  bottom: 0;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.form_content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
