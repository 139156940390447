.container {
  background: var(--bodyBackgroundColor);
}
.items {
  display: grid;
  grid-template-columns: var(--grid-fill-columns-3);
  grid-gap: var(--gutterSpacing);
  width: 100%;
  padding-top: 1.5rem;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  width: calc(100vw + var(--leftNavWidth));
  z-index: 999;
}

.radio_button {
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .items {
    grid-template-columns: var(--grid-columns-3);
  }
}
