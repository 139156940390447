.TrackerSite-list {
  padding: 20px;
}

.TrackerSite-list a {
  color: initial;
  transition: all ease-in-out 0.15s;
}

.TrackerSite-list a:hover {
  color: var(--primaryColor);
}
