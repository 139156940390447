.container {
  font-size: 14px;
  padding: 20px 20px 0 0;
}

.container:first-of-type {
  margin-left: 10px;
}

.link {
  color: var(--bodyText-dark);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--fontBold);
  padding-bottom: 12px;
  margin-bottom: 3px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ease-in-out 0.15s;
  white-space: nowrap;
}

.link:hover {
  color: var(--primaryColor);
}

.link_active {
  box-shadow: 0 4px 0 -0 var(--primaryColor);
  color: var(--primaryColor);
}

.disabled {
  pointer-events: none;
  color: var(--backgroundColor-dark);
}

.disabled.link_active {
  box-shadow: 0 4px 0 -1px var(--backgroundColor-dark);
}

.product {
  font: normal 1.1em/1 var(--fontUltra);
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.45);
}

.product:hover {
  color: rgba(0, 0, 0, 0.8);
}

.product.link_active {
  color: var(--primaryColor);
}

@media (max-width: 660px) {
  .product:not(.link_active) {
    color: #fff;
  }
}
