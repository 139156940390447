.subheading {
  font: 1.5rem var(--fontNeoBold);
  color: var(---headingColor);
  font-weight: initial;
}

.tabs {
  display: flex;
  overflow: auto;
  font: 1rem var(--fontNeoMedium);
}

@media (max-width: 767px) {
  .tabs {
    margin-bottom: 1rem;
  }
}
