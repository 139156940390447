.container {
  padding: var(--gutterSpacing);
  max-width: 100vw;
}

@media (min-width: 690px) {
  .container {
    max-width: calc(100vw - var(--leftNavWidth));
  }
}
