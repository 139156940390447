.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: var(--gutterSpacing);
  overflow: visible;
  max-width: 100vw;
}

.entry {
  position: relative;
  flex: 0 1 var(--columnThird);
  margin-bottom: 2%;
}

.entry_syntax {
  flex: 0 1 var(--columnFull);
  max-width: 100%;
  overflow-y: scroll;
}

.entry_name_container {
  display: flex;
  align-items: center;
}

.entry_name {
  flex: 4;
}

.entry ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.entry ul li {
  list-style-type: none;
  margin: 10px 0 0;
}

.entry ul li:first-of-type {
  margin: 0;
}

.entry_label {
  font-family: var(--fontUltra);
  margin-left: 2px;
}

.code {
  margin-right: 6px;
}

.code_span {
  font-family: var(--fontUltra);
  color: var(--colorDanger);
}

.entry_name_container {
  display: flex;
  position: relative;
}

@media (max-width: 660px) {
  .entry {
    flex: 0 1 var(--columnFull);
    margin-bottom: var(--gutterSpacing);
    max-width: 100%;
  }

  .container {
    overflow: hidden;
  }

  .entry_label {
    margin-left: 0;
  }

  .code {
    position: absolute;
    top: 0;
    right: 0;
    left: initial;
  }

  .entry_name_container {
    align-items: initial;
    flex-direction: column;
  }
}
