.status {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px;
  text-align: left;
}

.info_since {
  font-size: 14px;
  display: block;
  color: #888;
}

.loading {
  font: normal 15px/1.3 var(--fontMedium);
  background: rgba(255, 255, 255, 0.96);
  color: var(--bodyText-dark);
  height: 74px;
  width: 100%;
  position: absolute;
  top: -10px;
  right: 0;
  left: -10px;
  bottom: 0;
  padding: 10px;
}

.loading.locked {
  height: 64px;
}

.loading_inner {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.loading_text span {
  font-family: var(--fontUltra);
}

.loading_spinner {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
