.productSelect {
  display: none;
}

.product_list {
  padding: 0.7rem;
  position: relative;
  padding-bottom: 0;
  margin-bottom: 0.7rem;
}
.product_item {
  position: relative;
}
.items {
  cursor: pointer;
  border-left: solid 2px transparent;
  color: var(--magenta);
}

.items svg {
  height: 1.75rem;
  width: 1.75rem;
}

.active:after {
  content: '';
  width: 0;
  height: 2.2rem;
  position: absolute;
  border: 1px solid var(--magenta);
  top: 0.4rem;
  left: 0.2rem;
}

.wrapper .tooltip:after {
  padding-left: 2rem;
}

@media (max-width: 767px) {
  .productTabs {
    display: none;
  }

  .productSelect {
    display: block;
    max-width: 90px;
    padding: 5px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .active:after {
    height: 2.4rem;
    top: -1rem;
    left: 1.5rem;
    rotate: 90deg;
  }
}
