.container {
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--row-gap);
  margin: var(--row-gap) 0;
}
.footer_action {
  display: flex;
  margin-top: auto;
}
.service_title {
  font: 1.2rem var(--fontNeoMedium);
}
