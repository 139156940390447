.custom_select_wrapper {
  position: relative;
  display: block;
}

.input {
  cursor: pointer;
  background-color: var(--inputBackgroundColor);
  padding: 0.75rem;
  border-radius: 0.25rem;
  height: 3rem;
  font: 1rem var(--fontNeoRegular);
  color: #8c8c8c;
  border: 0.12rem var(--inputBackgroundColor);
  min-width: 13.62rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}

.select_options {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  position: absolute;
  cursor: pointer;
  width: 100%;
  min-width: 13.62rem;
  z-index: 99;
  border-left: solid 1px #f2f2f2;
}

.option_list,
.custom_add {
  padding: 0.5rem 0;
  font: 1rem/1.5rem var(--fontNeoMedium);
  cursor: pointer;
}

.option_list:hover,
.custom_add:hover {
  color: var(--magenta);
}

.custom_add {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.custom_add button {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_add input {
  width: 100%;
}

.check_icon {
  position: absolute;
  right: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
}
.close_icon {
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
}
.check_icon svg,
.close_icon svg {
  height: 1.5rem;
  width: 1.5rem;
}
@media only screen and (device-width: 768px) {
  .input {
    height: 100%;
  }
}
