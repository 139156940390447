.container {
  overflow: auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.select_margin {
  margin: 8px 0 1.2rem 0;
  width: 100%;
}
.button_wrapper {
  margin-top: auto;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.button_wrapper .execute {
  align-self: flex-end;
}

.eventType {
  margin-bottom: var(--gutterSpacing);
}
.eventType label {
  margin: 0 20px 0 0;
}
.form_content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.event_icon {
  padding-right: 0.3rem;
}
.label {
  padding-bottom: 0.4rem;
}
