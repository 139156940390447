.container {
  min-height: calc(100vh - var(--topHeaderHeight));
  height: 100%;
  margin-top: var(--topHeaderHeight);
}

.main_container {
  margin-top: var(--topHeaderHeight);
  min-height: inherit;
}

.right_panel {
  flex: 1 1;
  background: var(--bodyBackgroundColor);
  min-height: inherit;
  width: calc(100vw - (var(--leftNavWidth) + var(--leftAsidePanel))- 0.9rem);
  overflow-y: hidden;
  padding-left: 0.5rem;
}

.collapsed_panel {
  margin-left: calc(
    var(--leftCollapsedNavWidth) + var(--leftAsidePanel) - 0.9rem
  );
  padding-left: 0.5rem;
}
.right_panel {
  margin-left: calc(var(--leftNavWidth) + var(--leftAsidePanel) - 0.9rem);
}
.right_panel_full_width {
  width: calc(100vw - (var(--leftAsidePanel)) - 0.9rem);
  margin-left: calc(var(--leftAsidePanel) - 0.4rem);
}

.mobile {
  display: none;
}

@media (max-width: 991px) {
  .right_panel {
    margin-left: 0;
    width: 100%;
    padding-left: 0;
  }
  .main_container {
    min-height: calc(
      100vh - (var(--topHeaderHeight) + var(--mobileSubheaderHeight))
    );
    height: 100%;
    margin-top: calc(var(--topHeaderHeight) + var(--mobileSubheaderHeight));
    display: block;
    position: relative;
    margin-bottom: var(--mobileFooterHeight);
  }
  .container {
    min-height: calc(
      100vh - (var(--topHeaderHeight) + var(--mobileSubheaderHeight))
    );
    margin-top: 0;
  }
  .mobile {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .right_panel {
    margin-left: var(--leftAsidePanel);
    width: calc(100vw - (var(--leftAsidePanel) - 0.9rem));
  }
  .main_container {
    margin-bottom: 0px;
  }
}
