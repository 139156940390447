.container {
  width: 100%;
}
.sub_header {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-bottom: 0.5rem;
}
.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}
@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-2);
  }
}
.message {
  font: 1rem var(--fontNeoBold);
  text-align: center;
}
