.buildInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
}

.pipeline_date {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pipeline_name {
  font-weight: bold;
}

.build_details {
  border: 1px solid #ccc;
}
.error_message {
  word-break: break-all;
}
.test_method_name {
  width: 25%;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.build_details thead {
  background-color: #e20074;
}
.build_details thead th {
  color: #fff !important;
}
