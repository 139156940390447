.heading {
  flex: 1;
}

.section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--gutterSpacing) 0;
  position: relative;
}

.tabs_section {
  background-color: var(--backgroundColor-dark);
  display: flex;
  padding: var(--gutterSpacing);
}

.section_separator {
  border-top: 1px solid var(--backgroundColor-dark);
}

.device_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.avatar_container {
  width: 86px;
  height: 86px;
}

.avatar {
  background-size: cover;
  width: 100%;
  height: 100%;
  border: solid 6px #fff;
  box-shadow: var(--shadow);
  display: inline-block;
  border-radius: 50%;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-basis: 23%;
  gap: 0.4rem;
}

.time_display {
  display: block;
}

.row label {
  font-family: var(--fontUltra);
}

.row label span {
  font-family: var(--fontMedium);
}

.separator {
  border-bottom: 1px solid #cccccc;
  margin: 1.5rem 0;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}

.trip_items {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
