.container {
  width: 100%;
  position: relative;
}

.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}

.date_range_panel_position {
  margin-top: var(--dateRangeHeight);
}

.raw_data {
  display: flex;
  justify-content: flex-end;
}

.list {
  margin-top: var(--gutterSpacing);
}

.list thead th {
  white-space: nowrap;
}

.body_column {
  word-break: break-word;
  min-width: 180px;
}

.status {
  display: block;
  margin-bottom: var(--gutterSpacing);
  color: var(--primaryColor);
  font: normal 12px/1 var(--fontBold);
  text-decoration: none;
}

.status_icon {
  position: relative;
  top: 1px;
  left: 2px;
  transition: left ease-in-out 0.15s;
}

.status:hover .status_icon {
  left: 4px;
}

.-message {
  display: block;
  margin-top: var(--gutterSpacing);
}

.message span {
  font-family: var(--fontBold);
}

.input_container {
  margin-bottom: var(--gutterSpacing);
}

.input_container_inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  min-width: 84px;
}

.input {
  width: 100%;
}

.results_count span {
  position: relative;
  padding: 2px 11px 4px 6px;
  background: var(--backgroundColor-dark);
  border-radius: var(--borderRadius);
  margin: 0 6px;
  font: normal italic 0.9em/1em var(--fontRegular);
}

@media (max-width: 960px) {
  .status {
    position: relative;
    top: 0;
    display: block;
    margin-bottom: 10px;
    text-align: right;
  }
}

.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 1rem;
}
.controls_wrapper {
  margin-bottom: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}
.pagination_wrapper {
  justify-content: space-between;
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.subheading {
  font: 1.4rem var(--fontNeoMedium);
  color: var(---headingColor);
  font-weight: initial;
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.pageSize {
  padding: 0.5rem 0;
  width: 13rem;
}
.custom_search {
  flex-grow: 1;
}
.custom_pagination {
  flex: initial;
  margin: 0;
}
.custom_pagination li {
  padding: 0.5rem 0.8rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
}
.message {
  margin: 1rem 0 7rem 0;
  display: block;
  font: 1rem var(--fontNeoRegular);
}
.message_margin {
  margin: 1rem 0 1.5rem 0;
}
.custom_dropdown {
  margin-bottom: 0;
}
.date_filter {
  margin-bottom: 0;
}
.download_btn svg path {
  fill: var(--bodyText-light);
}
.pagination_buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.controls_wrapper.logs_controls {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .subheader {
    flex-direction: column;
    align-items: flex-start;
    gap: 0rem;
  }
  .controls {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .date_filter {
    width: 100%;
  }
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
  .pagination_wrapper {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .pagination_wrapper > :first-child,
  .controls_wrapper > :first-child {
    flex: 1 1 100%;
    width: 100%;
  }
  .date_range_panel_position {
    margin-top: calc(var(--dateRangeHeight) * 2);
  }
}

@media (min-width: 768px) {
  .controls_wrapper > :first-child {
    flex: 1 1 100%;
    width: 100%;
  }
  .pagination_wrapper > :first-child {
    flex: initial;
    width: initial;
  }
}
@media (min-width: 1200px) {
  .controls_wrapper.notification_controls > :first-child {
    flex-grow: 1;
  }
  .controls_wrapper > :first-child,
  .pagination_wrapper > :first-child {
    flex: initial;
    width: initial;
  }
}
