.action {
  display: flex;
  margin-top: var(--gutterSpacing);
  justify-content: space-between;
}

.action_btn {
  margin-right: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: flex-start;
  flex-basis: 23%;
  word-break: break-all;
}

.row label {
  font: 1rem var(--fontNeoMedium);
}

.row span {
  font: 1rem var(--fontNeoBold);
}
