.item {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
  margin-top: 10px;
  overflow: inherit;
  width: 100%;
}

.list_item {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list_item:last-of-type {
  margin: 0;
}

.list_item_name {
  margin-right: 10px;
  font: 1rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleting {
  width: 11px;
  height: 11px;
}

.deleting * {
  fill: #fff;
}

.add_devices {
  margin-top: var(--gutterSpacing);
}
.devices_list {
  margin: 2rem 0;
  border-top: 1px solid #ccc;
}
.header,
.results {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 0.4rem;
  text-align: center;
}
.heading {
  font: 1.4rem var(--fontNeoBold);
}
.item span {
  font: 1rem var(--fontNeoBold);
  padding: 1rem 0;
}
.container {
  position: relative;
}
.export_btn {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  text-decoration: none;
  transition: all 0.2s;
  border-radius: 6.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
