.commercial .LeftPanel,
.commercial .Header {
  display: none;
}

.commercial .Main-rightpanel {
  margin-left: 0;
}

.Widget-container {
  background: #fff;
  padding: 20px;
  margin-bottom: var(--gutterSpacing);
  box-shadow: var(--shadowMedium);
}

@media (max-width: 660px) {
  .commercial .Main-rightpanel {
    margin-top: 0;
  }
}
