.container {
  display: flex;
  width: 100%;
  max-width: 180px;
  align-items: center;
  cursor: pointer;
  color: var(--bodyText-dark);
  transition: var(--transition);
  margin: 0 0 var(--gutterSpacing);
}

.padded {
  padding: 0 0 var(--gutterSpacing);
}

.icon {
  font-size: 1.5em;
  margin-right: 5px;
  color: var(--bodyText-dark);
  transition: var(--transition);
}

.back_icon {
  margin-right: 0.5rem;
}

.container:hover .back_icon {
  fill: var(--primaryColor);
}

.container:hover,
.container:hover .icon {
  color: var(--primaryColor);
}
.primary {
  width: auto;
  max-width: 100%;
  margin: 0;
}
