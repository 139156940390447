.container {
  position: relative;
  background-color: var(--bodyBackgroundColor);
}

.disabled_message {
  color: var(--colorDanger);
  text-align: center;
  margin-top: 40px;
}

.results {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: calc(var(--gutterSpacing) - 0.2rem);
}

.user_id_column {
  cursor: pointer;
}
.delete_action {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .controls_wrapper {
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  .results {
    grid-template-columns: var(--grid-columns-1);
  }
}
@media screen and (max-width: 1366px) {
  .results {
    grid-template-columns: var(--grid-columns-2);
  }
}
