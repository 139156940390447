.container {
  display: flex;
  flex-direction: column;
}

.column {
  cursor: pointer;
  padding: 20px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
}

.open {
  background: rgba(0, 0, 0, 0.1);
}

.title {
  flex: 1;
}

.icon {
  margin-left: auto;
  color: inherit;
  transition: color 0.2s ease;
}

.column:hover .icon,
.column.open .icon {
  color: var(--magenta);
}

.content {
  background: rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.text {
  padding: 20px;
}
.checkbox_wrapper input[type='checkbox'] {
  accent-color: var(--primaryColor);
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
}
.container .column.open_accordion {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.help_text {
  padding-top: 0;
}
.faq_wrapper {
  padding: 0;
}
