.counts {
  display: flex;
  flex: 0 0 100%;
  margin: var(--gutterSpacing) 0;
}

.results {
  margin-top: var(--gutterSpacing);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 10px;
  overflow: inherit;
}

.no_results {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.no_results_filter {
  margin: 0 0 0 10px;
  background: rgba(0, 0, 0, 0.1);
  padding: 8px 11px;
  border-radius: var(--borderRadius);
  font-size: 0.85em;
}

.no_results_filter:last-of-type {
  margin-right: 0;
}

.no_results_filter span {
  padding: 0 4px;
  font-family: var(--fontUltra);
}
.item {
  flex-basis: var(--columnThird);
  margin-bottom: 2%;
}
@media (max-width: 1000px) {
  .item {
    flex-basis: var(--columnHalf);
  }
}
@media (max-width: 767px) {
  .item {
    flex-basis: var(--columnFull);
  }
}
