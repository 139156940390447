.name {
  width: 40%;
}
.type {
  width: 30%;
}
.required {
  width: 20%;
}
.title {
  font: normal 1rem/1 var(--fontNeoBold);
  margin: 1.5rem 0 0.5rem;
}
.title:first-child {
  margin-top: 0;
}
.request_url {
  word-break: break-all;
}
