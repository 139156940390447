.container {
  background: var(--bodyBackgroundColor);
}
.opList {
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px;
  width: 45%;
}
.opButton {
  margin-top: 10%;
  margin-left: 53%;
  margin-bottom: 2%;
}
/* .items {
  display: grid;
  grid-template-columns: var(--grid-fill-columns-3);
  grid-gap: var(--gutterSpacing);
  width: 100%;
  padding-top: 1.5rem;
} */

.spinner {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  width: calc(100vw + var(--leftNavWidth));
  z-index: 999;
}
.card_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 8.4rem;
  word-break: break-word;
}
.label {
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}
.value {
  font: 1rem var(--fontNeoBold);
  margin-left: 0;
  margin-top: 6px;
}
.infoIcon {
  display: flex;
  gap: 0.4rem;
}
/* 
.radio_button {
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .items {
    grid-template-columns: var(--grid-columns-3);
  }
} */
