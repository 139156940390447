.container {
  display: flex;
  width: 100%;
  padding: 0 var(--gutterSpacing);
  margin-bottom: var(--gutterSpacing);
  background: #fff;
  border: solid 1px var(--backgroundColor-dark);
  border-radius: var(--borderRadius-large);
  box-shadow: var(--shadowLight);
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 9;
}

@media (max-width: 660px) {
  .container {
    overflow-x: scroll;
  }
}
