.CommercialLeftPanel-container {
  background: var(--primaryColorDark);
  color: #fff;
  width: var(--leftNavWidth);
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.CommercialLeftPanel-logo-container {
  text-align: center;
  padding: 16px 25px;
}

.CommercialLeftPanel-logo {
  width: 36px;
  height: 36px;
}

@media (max-width: 660px) {
  .CommercialLeftPanel-container {
    display: none;
  }
}
