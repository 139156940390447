.container {
  background: #f2f2f2;
  border-bottom: solid 1px var(--backgroundColor-dark);
  display: flex;
  position: fixed;
  box-shadow: var(--shadowMedium);
  height: 100%;
  box-shadow: inset -2px 0px 4px rgba(0, 0, 0, 0.4);
  width: var(--leftAsidePanel);
  z-index: 999;
}

.mobile {
  display: none;
}

.product {
  color: var(--bodyText-dark);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 1.5rem;
}

.items {
  padding: 0.7rem;
}

.product_label {
  font: normal 14px/1 var(--fontBold);
  padding-right: 5px;
}

.product .product_select {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
}

.environments {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  font: normal 14px/1 var(--fontUltra);
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
  padding: 4px 0;
  height: 50px;
}

.environment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  height: 1.1em;
  width: 1px;
  text-align: center;
  margin: 0 15px;
  background: rgba(0, 0, 0, 0.3);
}

.link {
  font: normal 1.1em/1 var(--fontUltra);
  transition: all ease-in-out 0.15s;
}

.link:hover {
  color: rgba(0, 0, 0, 0.8);
}

.link_active {
  color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 1000px) {
  .environments {
    display: none;
  }
}

@media (max-width: 767px) {
  .container {
    height: var(--mobileFooterHeight);
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    border-top: solid 2px var(--primaryBorderColor);
    box-shadow: none;
    padding: 1rem;
  }

  .mobile {
    display: flex;
    align-items: center;
  }

  .logo {
    max-height: 48px;
    margin-left: var(--gutterSpacing);
    height: auto;
    width: auto;
  }

  .environments {
    display: none;
  }

  .product {
    flex: 1 1;
    justify-content: center;
    padding: 0;
  }
}
.items svg {
  height: 1.43rem;
  width: 1.43rem;
}
.active:after {
  content: '';
  width: 0;
  height: 2.2rem;
  position: absolute;
  border: 1px solid var(--magenta);
  top: 0.4rem;
  left: 0.1rem;
}
