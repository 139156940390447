.container {
  margin-top: calc(var(--gutterSpacing) / 2);
  padding: var(--gutterSpacing) var(--gutterSpacing) var(--gutterSpacing)
    calc(var(--gutterSpacing) - 10px);
  background-color: var(--backgroundColor);
  border: 1px solid var(--backgroundColor-dark);
}

.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.row {
  flex: 1;
  margin-right: var(--gutterSpacing);
}

.row:last-of-type {
  margin: 0;
}

.label {
  font-family: var(--fontBold);
  color: var(--bodyText-dark);
  font-size: 14px;
  margin-bottom: 10px;
}

.input_date {
  padding: 5px 10px;
  font: normal 15px/1 var(--fontBold);
  flex: 1;
  width: 100%;
  border: solid 1px var(--inputBorderColor);
}

.note {
  margin-top: 5px;
}

.button_container {
  flex: 0;
}

.button {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.btn_apply {
  margin-top: 1rem;
}

.input_primary {
  background-color: #f2f2f2;
  padding: 0.75rem;
  width: 100%;
  border-radius: 0.25rem;
  height: 2.5rem;
  font-size: 1rem;
  font: 1rem var(--fontNeoRegular);
  color: #8c8c8c;
  border: 0.12rem #f2f2f2;
}

.input_primary:hover {
  background-color: #e8e8e8;
}

.no_margin_right {
  margin-right: 0;
}
@media (max-width: 767px) {
  .row {
    margin-right: 0;
  }
  .column {
    gap: 1rem;
  }
}
