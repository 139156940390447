.user {
  padding-right: 2rem;
}
.user_dropdown {
  color: #fff;
}
.user_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.user_list_item {
  list-style: none;
  display: flex;
  flex-direction: column;
  color: var(--headingColor);
}
.user_list_item_heading {
  flex: 1 1;
  font-family: var(--fontRegular);
  color: #757575;
}
.groups_list {
  padding: 0 0 0 20px;
  margin: 0;
}
.groups_list_item {
  margin-top: 1rem;
  list-style-type: initial;
  font-family: var(--fontBold);
}
.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--gutterSpacing);
}
.logout_icon {
  position: relative;
  left: 6px;
}
.logout_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
