.container {
  display: flex;
  flex-direction: column;
}

.container > h3,
.container > select {
  margin-bottom: var(--gutterSpacing);
}

.container > *:last-child {
  margin-bottom: 0;
}

.select_margin {
  margin: 8px 0 10px;
  width: 100%;
}

.button_wrapper {
  margin-top: auto;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.button_wrapper .execute {
  align-self: flex-end;
}

.form_content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.label {
  padding-bottom: 0.4rem;
}
