.container {
  width: 100%;
}

.row {
  display: flex;
}

.column {
  flex: 1;
}

.label {
  padding: 5px 0;
}

.label label {
  font-family: var(--fontUltra);
}

.label label span {
  font-family: var(--fontMedium);
}

.container {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.4rem;
  row-gap: 1.5rem;
}
.row {
  display: flex;
  padding: 10px 0 0 0;
  flex-wrap: wrap;
  flex-basis: 25%;
  flex-direction: column;
}
.separator {
  border-bottom: 1px solid #cccccc;
  margin: 1.5rem 0;
}
.timestamp {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.timestamp label {
  display: none;
}
