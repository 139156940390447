.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.floorplan_trigger {
  cursor: pointer;
  color: var(--primaryColor);
}

.alerts {
  display: flex;
  background: var(--backgroundColor-dark);
  padding: 20px;
  margin-bottom: var(--gutterSpacing);
}

.alerts_icon {
  width: 20px;
  height: 20px;
  fill: transparent;
}

.alerts_header {
  margin: 0 15px;
}

.alerts_list_item {
  margin-bottom: 5px;
}

.loading_element {
  height: 100%;
}

.map {
  height: 350px;
  width: 100%;
  position: relative;
  margin: var(--gutterSpacing) 0;
}
