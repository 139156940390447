.logo {
  max-width: 100%;
}

.logo img {
  max-height: 5rem;
  width: auto;
}

@media (max-width: 991px) {
  .logo img {
    width: 100%;
    height: auto;
  }
}
