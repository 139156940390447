.panel_title {
  color: #fff;
  font: 1.5rem var(--fontNeoBold);
  text-transform: uppercase;
  margin-top: 2rem;
}

.search_section {
  display: flex;
  position: relative;
}

.search_icon {
  position: absolute;
  padding-left: 0.5rem;
  left: 0;
  top: 0.6rem;
}
.search_section .search_input {
  padding-left: 2.5rem;
  height: 3rem;
}
.close_icon {
  top: 0.7rem;
  position: absolute;
  right: 0.625rem;
  fill: #4c4c4c;
  cursor: pointer;
}
.input {
  padding: 0.625rem 2rem !important;
  width: 100%;
}

.product_select {
  border-left: none;
}

.user_result_container {
  flex: 2 2 auto;
  overflow-y: auto;
  min-height: 0;
  color: #fff;
}

.no_result {
  flex: 2 2 auto;
}

.user_card {
  display: flex;
  padding: 1rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  align-items: center;
  color: #ffffff;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.user_card:hover,
.card_selected {
  background: #4c4c4c;
}
.user_info {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  min-width: 0;
  flex: 1;
}
.title {
  font: var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sub_title {
  font: var(--fontNeoRegular);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product_icon {
  width: 1.75rem;
  height: 1.75rem;
  color: var(--primaryColor);
}
#scrollable::-webkit-scrollbar {
  width: 0.625rem;
}

#scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.3);
  -webkit-box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.3);
  border-radius: 0.5rem;
}

#scrollable::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.5);
  -webkit-box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.5);
}
/*top section*/
.filter_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  margin: 1rem 0.5rem;
}
.filter_content {
  margin-top: 1.3rem;
  min-width: 7rem;
}
.filter_button button {
  padding: 0.4rem 1rem;
}
/* for tlife search */
.radio_style {
  margin-bottom: 12px;
}
.radio_style [type="radio"]:checked + label, .radio_style [type="radio"]:not(:checked) + label {
  color: white;
}
.loadMore{
  margin-bottom:40%;
  text-align: -webkit-center;
}
.Load_button{
  background-color:#e20074 !important;
  color:#ffffff !important;
  text-decoration: none !important;
  padding: 12px !important;
}

@media (max-width: 767px) {
  .panel_title {
    display: none;
  }
  .search_section {
    margin-top: 1rem;
  }
}
