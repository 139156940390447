.container {
  border: 2px solid transparent;
  background-color: #fafafa;
  border-radius: var(--borderRadius);
  max-width: 480px;
  box-shadow: var(--shadowMedium);
  padding: calc(var(--gutterSpacing) / 2);
  margin-top: var(--gutterSpacing);
  display: flex;
  position: relative;
  cursor: pointer;
  animation: fadeIn 0.5s;
}

.shown {
  animation: fadeIn 0.5s;
}

.exiting {
  animation: fadeOut 0.5s;
}

.header {
  display: flex;
  align-items: center;
}

.message {
  margin-left: 10px;
}

.success {
  background-color: var(--colorSuccess);
  color: var(--bodyText-light);
}

.info {
  background-color: var(--colorInfo);
  color: var(--bodyText-light);
}

.warning {
  background-color: var(--colorWarning);
  color: var(--bodyText-light);
}

.error {
  background-color: var(--colorDanger);
  color: var(--bodyText-light);
}

.icon {
  width: 25px;
  height: 25px;
  color: var(--bodyText-light);
}

.details {
  margin: 0.2rem 0 0 0.6rem;
}

@keyframes fadeIn {
  from {
    bottom: -20px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -20px;
    opacity: 0;
  }
}
