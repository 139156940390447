.InfoBlock-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.InfoBlock-div {
  padding: 16px;
  min-height: 120px;
  border-radius: 6px;
  background: var(--backgroundColor-dark);
  margin-right: 20px;
  flex: 1 1 calc(50% - 10px);
}
.InfoBlock-div:nth-last-child(2),
.InfoBlock-div:last-of-type {
  margin-top: 20px;
}
.InfoBlock-div:nth-of-type(even) {
  margin-right: 0;
}

.InfoBlock-content {
  display: block;
  font: normal 40px/46px var(--fontUltra);
}

.InfoBlock-content small {
  color: rgba(0, 0, 0, 0.85);
  font: normal 16px/1 var(--fontBold);
  display: inline-block;
  position: relative;
  left: -7px;
}

.InfoBlock-content span {
  color: rgba(0, 0, 0, 0.85);
  font: normal 16px/1 var(--fontBold);
  display: block;
  vertical-align: bottom;
  margin-top: 12px;
}
