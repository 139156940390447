.items {
  display: grid;
  grid-template-columns: var(--grid-columns-4);
  grid-gap: var(--gutterSpacing);
}

.row {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 0.4rem;
  margin-bottom: 1rem;
}
.label {
  font: 1rem/1rem var(--fontNeoMedium);
}
.value {
  font: 1rem/1rem var(--fontNeoBold);
}

.status_pill {
  border-radius: 0.2rem;
  padding: 0.4rem;
  font: 0.8rem var(--fontNeoMedium);
}
.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.card_title {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.status {
  margin: 0.2rem 0 0.6rem;
}

.task_name {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action_controls {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}
.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--column-gap);
}
.schedule {
  border-bottom: 1px solid #cccccc;
  min-height: 3rem;
  margin: 0.5rem 0;
}
.schedule_day {
  text-transform: uppercase;
  font: 1rem var(--fontNeoBold);
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .action_controls {
    flex-direction: column;
  }
}
