.container {
  background: var(--bodyBackgroundColor);
}

.results {
  display: grid;
  grid-template-columns: var(--grid-columns-2);
  grid-gap: calc(var(--gutterSpacing) - 0.2rem);
}

.explore_input_icon {
  position: absolute;
  left: 2.5rem;
  bottom: 2.9rem;
}

.label {
  min-width: 84px;
}

.input_container {
  margin-bottom: var(--gutterSpacing);
}

.input_container_inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.input_inline {
  padding: 12px 20px 12px 8px;
}

.input {
  width: 100%;
}

.close_input_icon {
  position: absolute;
  right: 1.5rem;
  /* bottom: 11.3rem; */
  cursor: pointer;
}

.pagination_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.page_size {
  width: 13rem;
}

.disabled_message {
  color: var(--colorDanger);
  text-align: center;
  margin-top: 40px;
}

.controls .search {
  flex: 1;
}

.controls {
  border: 0;
}

.search_control {
  background-color: #f2f2f2;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  border: 0;
}

.custom_pagination li {
  padding: 0.5rem 0.8rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
}

@media (max-width: 767px) {
  .results {
    grid-template-columns: var(--grid-columns-2);
  }
}

.sort_control {
  width: 8rem;
}

@media (max-width: 460px) {
  .results {
    grid-template-columns: var(--grid-columns-1);
  }
}
