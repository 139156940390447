/* Page Header*/
.page_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--pageBorderColor);
  padding: 0 0 1rem;
  gap: var(--column-gap);
  margin-bottom: 1rem;
}
.secondary_page_header {
  align-items: flex-start;
  flex-direction: column;
  box-shadow: none !important;
  gap: 0.5rem;
}
.secondary_page_header .controls {
  align-items: flex-start;
  justify-content: flex-start;
}
.title_wrapper {
  display: flex;
  align-items: center;
  gap: var(--column-gap);
}
.back_button {
  margin-bottom: 0;
}
.title_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  white-space: nowrap;
}
.title {
  font: 1.6rem/1 var(--fontNeoBold);
  text-transform: uppercase;
  color: var(--headingColor);
  margin: 0;
}
.sub_title {
  font: 0.875rem var(--fontNeoRegular);
  color: var(--subTitleColor);
}
.controls {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}
/* Tab Header*/
.tab_header {
  border-bottom: none;
  margin-bottom: 0;
}
.tab_header .title {
  font: 1.4rem/1 var(--fontNeoMedium);
  text-transform: initial;
}

.analytics_header .title {
  font: 1.4rem/1 var(--fontNeoMedium);
}

@media (max-width: 767px) {
  .page_header {
    flex-direction: column;
    align-items: flex-start;
  }
  .controls {
    justify-content: flex-start;
    gap: var(--column-gap);
  }
}

@media (max-width: 1199px) {
  .page_header_search_controls .controls > :first-child {
    flex: 1 1 100%;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .page_header_search_controls .controls > :nth-child(2) {
    flex: 1;
  }
}
@media (min-width: 768px) {
  .tab_group {
    margin: 0 2rem !important;
    border-bottom: none !important;
  }
}
