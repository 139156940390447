.container {
  background: var(--bodyBackgroundColor);
}

.created_date {
  display: inline-block;
  font-family: var(--fontBold);
}

.created_date.warning {
  background-color: var(--colorDanger);
  padding: 5px 10px;
  color: var(--bodyText-light);
}

.ONBOARDED,
.ONBOARDING,
.FAILED {
  padding: 10px;
  color: var(--bodyText-light);
}

.ONBOARDED {
  color: var(--colorSuccess);
}

.ONBOARDING {
  color: var(--colorWarning);
}

.FAILED {
  color: var(--colorDanger);
}

.device_count {
  display: inline-block;
  margin: 0 var(--gutterSpacing) var(--gutterSpacing) 0;
  font-family: var(--fontBold);
}

.device_count span {
  margin-left: 5px;
  font-family: var(--fontUltra);
}
