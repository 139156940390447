.heading {
  margin: 0 0 var(--gutterSpacing) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spinner {
  position: absolute;
  background: #fff;
  width: 100%;
}
.groups_spinner {
  position: absolute;
  display: block;
  width: 100%;
}
.spinner_wrapper {
  position: relative;
  width: 100%;
}

.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
  margin-top: 10px;
  overflow: inherit;
}

.devices {
  width: 100%;
}

.delete_btn {
  width: 100%;
}

.max_width {
  width: 100%;
}

.edit {
  display: flex;
  padding: 0;
  justify-content: space-between;
  width: 100%;
}

.device_list {
  margin-top: var(--gutterSpacing);
  background: var(--backgroundColor);
  padding: var(--gutterSpacing);
  border: solid 1px var(--backgroundColor-dark);
  border-radius: var(--borderRadius);
  max-height: 400px;
  overflow: scroll;
}
.spinner_group_deleting {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
}
.group_container {
  position: relative;
}

.items {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card_header,
.card_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.card_footer {
  margin-bottom: 0;
  padding-top: 1.5rem;
}

.card_title {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.action_controls {
  display: flex;
  justify-content: right;
  gap: 1rem;
}

.message {
  margin: 1rem 0 1.5rem 0;
  display: block;
  font: 1rem var(--fontNeoRegular);
}
.custom_dropdown {
  margin-bottom: 0;
}

.results {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: calc(var(--gutterSpacing) - 0.2rem);
}
.display_name {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
  flex-grow: 1;
  position: relative;
}
.textarea {
  background: #f2f2f2;
  border-radius: 0.25rem;
  padding: 0.75rem;
  font: 1rem 'Tele-Neo-Regular';
  color: #8c8c8c;
  border: 0;
  margin-bottom: 0.8rem;
  width: 100%;
}
.form_container .label {
  font: 1rem var(--fontNeoRegular);
  margin-bottom: 0.5rem;
}
.add_device_action {
  display: flex;
}

@media (max-width: 1400px) {
  .results {
    grid-template-columns: var(--grid-columns-2);
  }
}
@media (max-width: 768px) {
  .edit {
    flex-direction: column;
    gap: var(--column-gap);
    align-items: center;
  }
}
