.container {
  overflow-y: scroll;
}

.content {
  margin-top: var(--gutterSpacing);
}

.header_container {
  display: flex;
  justify-content: space-between;
}
