.container {
  background: no-repeat center / cover;
  height: 100%;
  width: 100%;
  border-radius: var(--borderRadius-large);
}
.img_container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 3.2rem;
  justify-content: center;
  transform: translateY(0);
  max-height: 100vh;
}
