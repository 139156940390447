.container {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
  flex-grow: 1;
}

.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}
.card_title {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.action_controls {
  display: flex;
  justify-content: right;
  gap: 1rem;
}

.scope_name {
  font: 0.875rem var(--fontNeoRegular);
  padding-top: 0.2rem;
  color: var(--subTitleColor);
}
.user_name {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
