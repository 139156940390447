.container {
  position: relative;
}

.trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all ease-in-out 0.15s;
}

.trigger {
  color: var(--bodyText-dark);
  line-height: 0;
  transition: all ease-in-out 0.15s;
}

.trigger:hover .trigger,
.container_open .trigger {
  color: var(--primaryColor);
}

.trigger_text {
  padding-right: 8px;
}

.content {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 98;
  min-width: 180px;
  padding: 10px;
  background: #fff;
  border-radius: var(--borderRadius-large);
  border: solid 1px var(--backgroundColor);
  box-shadow: var(--shadowMedium);
  animation: fadeIn 0.15s;
}

.content_open {
  animation: fadeIn 0.15s;
}

/* -- Themes -- */
.user .trigger {
  color: #fff;
  justify-content: flex-start;
  font-family: var(--fontUltra);
}
.user .content {
  background: #fff;
  left: 0;
  margin-bottom: 10px;
  right: initial;
  padding: 1rem;
  top: calc(var(--topHeaderHeight) - 0.2rem);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  width: 100%;
}
.user_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: initial;
}
.avatar {
  height: 2rem;
  width: 2rem;
  background: #fff;
  color: #e20074;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1rem;
  margin-right: 0.5rem;
}
.info {
  display: flex;
  flex-direction: column;
  font: 1rem var(--fontNeoRegular);
  gap: 0.3rem;
  margin-right: 1rem;
}
.email {
  text-transform: lowercase;
  font-size: 0.87rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .info {
    display: none;
  }
  .user .content {
    right: 0;
    left: unset;
  }
}
