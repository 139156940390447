.container {
  background-color: var(--bodyText-dark);
  flex-direction: row;
  justify-content: space-between;
  max-width: var(--leftNavWidth);
  min-width: var(--leftNavWidth);
  /* max-height: calc(100vh - (var(--gutterSpacing) * 2)); */
  z-index: 9;
  padding: 0 16px var(--gutterSpacing);
  box-sizing: border-box;
  overflow-y: auto;
  box-shadow: var(--shadowMedium);
  position: fixed;
  height: 100%;
  left: var(--leftAsidePanel);
}

.search_container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.logo_container {
  margin-top: 1rem;
}

@media (max-width: 660px) {
  .user_icon {
    width: 30px;
    font-size: 30px;
  }
}

.collapsed {
  width: var(--leftCollapsedNavWidth);
  background-color: var(--bodyText-dark);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 16px var(--gutterSpacing);
  z-index: 9;
  box-sizing: border-box;
  overflow-y: auto;
  box-shadow: var(--shadowMedium);
  position: fixed;
  height: 100%;
  left: var(--leftAsidePanel);
}

.logo_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger {
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font: 1rem/1rem var(--fontNeoRegular);
  margin: 0.5rem;
  text-decoration: none;
  margin-bottom: 0.2rem;
  border-radius: var(--borderRadius-large);
}

#scrollable::-webkit-scrollbar {
  width: 0.625rem;
}

#scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.3);
  -webkit-box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.3);
  border-radius: 0.5rem;
}

#scrollable::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.5);
  -webkit-box-shadow: inset 0 0 4px rgba(207, 207, 207, 0.5);
}

@media (max-width: 991px) {
  .logo_container {
    display: none;
  }

  .container {
    left: calc(var(--leftNavWidth) * -1);
    width: var(--leftNavWidth);
    transition: all 0.5s ease-in-out;
    max-height: calc(100vh - (var(--gutterSpacing) * 3));
  }
  .mobile_menu {
    left: 0;
    width: var(--leftNavWidth);
    z-index: 999;
    box-shadow: none;
  }
  .collapsed {
    left: 0;
    width: var(--leftNavWidth);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mobile_menu {
    left: var(--leftAsidePanel);
  }
}
