.row {
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 0;
  align-items: baseline;
}
.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}

.label {
  font-family: var(--fontUltra);
}

.label span {
  font-family: var(--fontMedium);
}

.action {
  margin-top: var(--gutterSpacing);
  display: flex;
  align-content: baseline;
  width: 100%;
  justify-content: space-between;
}

.link {
  margin: 10px 0 0 auto;
  text-align: center;
}

.link_icon {
  position: relative;
  top: 2px;
  left: 0;
  margin-left: 3px;
  transition: all ease-in-out 0.15s;
}

.link:hover .link_icon {
  left: 3px;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}

.action {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.action > * {
  margin-bottom: 10px;
}

.action > *:last-child {
  margin-bottom: 0;
}
.action_controls {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}

.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.card_title {
  width: 75%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  flex-grow: 1;
}

.avatar_details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 70%;
}

.active_days {
  color: var(--magenta);
}
.days {
  padding: 0.18rem;
}
.product {
  font: 0.875rem var(--fontNeoRegular);
  padding-top: 0.2rem;
  color: var(--subTitleColor);
}
.display_name {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.role_name {
  font: 0.875rem var(--fontNeoRegular);
  padding-top: 0.2rem;
  color: var(--subTitleColor);
}
.invited_member {
  width: fit-content;
  background: var(--colorWarning);
  color: #fff;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

.avatar {
  height: 3rem;
  width: 3rem;
  background: #fff;
  color: #e20074;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.25rem;
  margin-right: 0.5rem;
  background: #e8e8e8;
  overflow: hidden;
}

.avatar_img {
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}
.button_redeem:hover {
  background: transparent;
}
