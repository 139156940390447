.container {
  background: var(--bodyBackgroundColor);
  min-height: 100vh; /* temp added need to remove once layout design done*/
}
.tab_container {
  display: flex;
  width: 100%;
  margin-bottom: var(--gutterSpacing);
  align-items: center;
  justify-content: space-between;
}
.tabs {
  display: flex;
  min-width: 0;
}
.close_tabs_button {
  min-width: 6rem;
  padding: 0;
}

.link {
  color: #8c8c8c;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all ease-in-out 0.15s;
  white-space: nowrap;
  position: relative;
  padding-right: 2rem;
  overflow: hidden;
  font-family: var(--fontNeoBold);
  text-transform: uppercase;
  font-size: 1rem;
  margin-right: 1rem;
  border-bottom: 4px solid var(--bodyBackgroundColor);
}
.link .title {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.link .title svg {
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.close_icon {
  width: 2rem;
  height: 1.6rem;
  background: inherit;
  position: absolute;
  right: -0.8rem;
  top: 0.5rem;
  padding-left: 0.4rem;
  background: var(--bodyBackgroundColor);
}
.close_icon svg {
  width: 0.8rem;
  height: 0.8rem;
}
.link_active {
  color: var(--primaryColor);
  border-bottom: 4px solid var(--primaryColor);
}
.link_active .close_icon svg {
  color: var(--primaryColor);
}

@media (max-width: 767px) {
  .tab_container {
    gap: 0.5rem;
  }
  .tab_container {
    flex-wrap: wrap;
  }
  .tabs {
    overflow: scroll;
  }
  .link {
    overflow: initial;
  }
}
