.container {
  background: var(--bodyBackgroundColor);
  font-family: var(--fontNeoMedium);
}
.section_header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.dynamic_list .name {
  width: 20%;
  min-width: 100px;
}

.dynamic_list .description {
  width: 30%;
  min-width: 200px;
}

.dynamic_list .api_route {
  width: 25%;
  min-width: 200px;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dynamic_list .row_object {
  width: 160px;
  min-width: 160px;
}

.dynamic_list .status {
  width: 100px;
  text-align: center;
}
.dynamic_list .action {
  text-align: center;
}
.status_text {
  top: 0;
  left: 0;
  text-transform: uppercase;
}

.spinner {
  animation: spin infinite 2s linear;
}

.dynamic_list .spinner {
  color: var(--magenta);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.last_updated {
  margin-left: auto;
  margin-right: 15px;
}

.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: calc(var(--gutterSpacing) - 0.2rem);
}

@media (max-width: 1024px) {
  .items {
    grid-template-columns: var(--grid-columns-2);
  }
}

@media (max-width: 768px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}
.tabs_group {
  overflow-y: auto;
  box-shadow: none;
}
@media screen and (max-width: 767px) {
  .control_wrapper {
    justify-content: space-between;
  }
}
