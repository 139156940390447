.map_container {
  height: 150px;
  background: #fff;
  border-radius: var(--borderRadius-large);
  margin-bottom: 1rem;
}

.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--column-gap);
  border-bottom: solid 1px #8c8c8c;
  padding-bottom: var(--gutterSpacing);
}

.column {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.event_container {
  margin-top: 1rem;
}

.trip_item {
  border-bottom: solid 1px #ccc;
}
