.input {
  border: solid 1px var(--inputBorderColor);
  border-radius: var(--borderRadius);
  background: #fff;
  padding: 5px 7px;
  font-family: var(--fontMedium);
  font-size: 14px;
  transition: border ease-in-out 0.1s;
}

.input:focus,
.input:not(:disabled):hover {
  border: solid 1px #bbb;
}

.lg {
  padding: 10px 15px;
  font-size: 16px;
}

.icon {
  padding-left: 32px;
}

.input:disabled {
  pointer-events: none;
  border: solid 1px var(--colorDisabled);
}

.input:disabled + label {
  color: var(--colorDisabled);
  pointer-events: none;
}
