.container {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
}

.label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 -10px;
}

.container .input + .button {
  display: inline-block;
  vertical-align: middle;
}

.input {
  display: none;
}
.input::selection,
.input:after::selection,
.input:before::selection,
.input *::selection,
.input *:after::selection,
.input *:before::selection,
.input + .button::selection {
  background: none;
}
.input + .button {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input + .button:after,
.input + .button:before {
  position: relative;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
}
.input + .button:after {
  left: 0;
}
.input + .button:before {
  display: none;
}
.input:checked + .button:after {
  left: 50%;
}

.input + .button {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 2em;
  padding: 2px;
  transition: all 0.3s ease;
}
.input + .button:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.3s ease;
}
.input:checked + .button {
  background: var(--primaryColor);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 0 #fff;
}
.input:checked + .button:after {
  box-shadow: var(--shadowMedium);
}

.input + .sm {
  width: 2em;
  height: 1em;
}
.input + .md {
  width: 3em;
  height: 1.5em;
}
.input + .lg {
  width: 4em;
  height: 2em;
}
