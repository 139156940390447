.heading {
  flex: 1;
}

.section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--gutterSpacing) 0;
  position: relative;
}

.tabs_section {
  background-color: var(--backgroundColor-dark);
  display: flex;
  padding: var(--gutterSpacing);
}

.section_separator {
  border-top: 1px solid var(--backgroundColor-dark);
}

.device_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.row {
  display: flex;
  flex-direction: column;
  flex-basis: 23%;
  justify-content: left;
  gap: 0.4rem;
  word-break: break-all;
}

.time_display {
  display: block;
}

.row label {
  font: 1rem var(--fontNeoMedium);
}

.row span {
  font: 1rem var(--fontNeoBold);
}

.separator {
  border-bottom: 1px solid #cccccc;
  margin: 1.5rem 0;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}
