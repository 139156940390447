.filter_container {
  margin-bottom: var(--gutterSpacing);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter_select_container {
  flex: 0 1 15%;
  align-self: flex-end;
}

.filter_select_container:last-of-type {
  margin-right: 0;
}

.filter_select {
  width: 100%;
}

.filter_label {
  display: block;
  font: normal 14px/1 var(--fontBold);
  margin-bottom: 5px;
}

.filter_date {
  margin-bottom: var(--gutterSpacing);
}

.filter_chips {
  margin-top: var(--gutterSpacing);
}

.filter_chips_chip {
  font: 12px/1 var(--fontBold);
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 8px 2px 8px 12px;
  margin: 0 8px 8px 0;
  border-width: 0;
  outline: none;
  cursor: pointer;
  top: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0);
  transition: all ease 0.18s;
}

.filter_chips_chip div {
  overflow: hidden;
  max-width: 400px;
  padding-right: 10px;
  flex-flow: wrap;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter_chips_chip:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.filter_chips_chip span {
  padding-right: 5px;
  font-family: var(--fontUltra);
}

.filter_chip_dismiss {
  vertical-align: middle;
  margin-left: 8px;
  position: relative;
  top: -1px;
  transition: all ease-in-out 0.1s;
}

.filter_chip_dismiss:hover {
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 660px) {
  .filter_dropdown {
    width: 100%;
    margin: 0 auto 10px;
  }
  .filter_select_container {
    flex: 0 0 100%;
    margin: 0 auto 10px;
  }
}
