.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  overflow: auto;
  flex: 1 1;
}
.button_wrapper {
  background: #fff;
  border-top: 1px solid var(--modalFooterBorderColor);
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.select_margin {
  margin: 0.4rem 0 1.2rem;
}
