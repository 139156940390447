.container {
  width: 100%;
}

.searchbar_container {
  position: relative;
  z-index: 1;
}

.searchbar_container_inner {
  padding-top: var(--gutterSpacing);
}

@media (min-width: 660px) {
  .searchbar_container {
    position: sticky;
    top: 0;
  }
}
