.container {
  margin-left: 5px;
}

.success,
.true {
  color: var(--colorSuccess);
}

.alert,
.false {
  color: var(--colorDanger);
}

.warning {
  color: var(--colorWarning);
}
