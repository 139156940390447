.actions {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  margin-top: 1rem;
}
.label {
  margin-bottom: 0.4rem;
  display: block;
}
