.container {
  display: flex;
  justify-content: space-between;
  padding: 10px var(--gutterSpacing) 10px 0;
  border-bottom: 2px solid var(--backgroundColor-dark);
}

.container:hover .remove {
  display: flex;
}

.remove,
.name {
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.remove {
  padding: 0;
  display: none;
  cursor: pointer;
  color: var(--primaryColor);
  font-size: 30px;
}

.remove:hover {
  color: var(--primaryColor-hover);
}

.remove_name {
  font-family: var(--fontUltra);
}
