.action {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.action button {
  margin-bottom: 5px;
}
.dropdown_button {
  display: flex;
  justify-content: center;
}
.copy_clipboard form {
  display: none;
}
.curl_request {
  margin-top: 20px;
  line-height: 20px;
}
.curl_request span {
  color: var(--magenta);
}
.text_area {
  width: 100%;
  min-height: 100px;
  margin-top: 4px;
}

.button_wrapper {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
}
.button_wrapper button {
  align-self: flex-end;
}

.spinner {
  animation: spin infinite 2s linear;
  margin-right: 4px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.response_wrapper {
  border: 1px solid #ccc;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
}
.text_area_error {
  border-color: var(--colorDanger);
}
.error_msg {
  color: var(--colorDanger);
}
.response_wrapper pre {
  background: #f9f9f9 !important;
  border-radius: 0;
}
.input_grid {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.scrollable_content {
  overflow: auto;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  font-family: var(--fontNeoMedium);
}
.modal_tabs > div {
  font-size: 0.875rem;
}
.response_structure {
  display: flex;
  flex-direction: column;
}
.response_action {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}
.response_action > div {
  width: auto;
}
.response_title {
  font: normal 1rem/1 var(--fontNeoBold);
  margin: 0 0 0.5rem;
}
.tooltip::after {
  width: 7rem;
}
@media screen and (max-width: 767px) {
  .tabs_groups {
    overflow-y: auto;
  }
}
