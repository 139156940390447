.container {
  padding: 0 var(--gutterSpacing);
}

.list_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list {
  margin-top: 10px;
  flex-basis: var(--columnHalf);
  max-height: 600px;
  overflow-y: scroll;
}

.list:nth-of-type(even) {
  margin-right: 0;
}

@media (max-width: 660px) {
  .list {
    margin: 0 auto var(--gutterSpacing);
    flex: 0 0 100%;
  }
}
