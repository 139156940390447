.FloorPlanModal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
}

.FloorPlanModal-close:hover {
  color: var(--primaryColor);
}

.FloorPlanModal-heading {
  margin-top: 0;
}

.FloorPlanModal-row {
  margin-bottom: 10px;
}

.FloorPlanModal-row label {
  font-family: var(--fontUltra);
}

.FloorPlanModal-row label:nth-of-type(even) {
  margin-left: 10px;
}

.FloorPlanModal-battery-icon,
.FloorPlanModal-lock-icon {
  width: 35px;
  height: 18px;
  position: relative;
  top: 5px;
  margin-left: 5px;
  fill: transparent;
}

.FloorPlanModal-lock-icon {
  top: 3px;
}
