.list {
  margin: 0;
  padding: 0 0 0 var(--gutterSpacing);
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #8c8c8c;
}

.container .row {
  display: flex;
  flex-direction: column;
  padding: 0;
  flex-basis: 23%;
  word-break: break-all;
}

.row .label {
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}

.row .value {
  font: 1rem var(--fontNeoBold);
}
