.container {
  width: 100%;
}

.loading_element {
  height: 100%;
  width: 100%;
  border-radius: var(--borderRadius-large);
}

.row {
  display: flex;
  padding-top: var(--gutterSpacing);
}

.column {
  flex: 1;
}

.label {
  padding: 5px 0;
}

.label label {
  font-family: var(--fontUltra);
}

.label label span {
  font-family: var(--fontMedium);
}

.raw_data {
  margin-top: 10px;
  display: inline;
}

.map {
  align-items: center;
  background-color: var(--backgroundColor);
  display: flex;
  height: 400px;
  justify-content: center;
  width: 100%;
  border-radius: var(--borderRadius-large);
  position: relative;
}

.map_text {
  color: #999999;
  font-size: 20px;
  text-transform: uppercase;
}

.history_map {
  margin-top: 30px;
}
.data_wrapper {
  padding: var(--gutterSpacing) 0;
  border-bottom: 1px solid var(--pageBorderColor);
}

@media (max-width: 767px) {
  .history_map {
    margin-top: 5rem;
  }

  .raw_data {
    display: inline;
  }
}
