@font-face {
  font-family: 'Tele-Thin';
  src: url('telegrotesknext-thin-webfont.woff2') format('woff2'),
    url('telegrotesknext-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Tele-Regular';
  src: url('telegrotesknext-regular-webfont.woff2') format('woff2'),
    url('telegrotesknext-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Tele-Medium';
  src: url('telegrotesknext-medium-webfont.woff2') format('woff2'),
    url('telegrotesknext-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Tele-Bold';
  src: url('telegrotesknext-bold-webfont.woff2') format('woff2'),
    url('telegrotesknext-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Tele-Ultra';
  src: url('telegrotesknext-ultra-webfont.woff2') format('woff2'),
    url('telegrotesknext-ultra-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tele-Neo-Thin';
  src: url('tele_neo_thin-webfont.woff2') format('woff2'),
    url('tele_neo_thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tele-Neo-Regular';
  src: url('tele-neo-regular-webfont.woff2') format('woff2'),
    url('tele-neo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tele-Neo-Bold';
  src: url('tele_neo_bold-webfont.woff2') format('woff2'),
    url('tele_neo_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tele-Neo-Ultra';
  src: url('tele_neo_extrabold-webfont.woff2') format('woff2'),
    url('tele_neo_extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tele-Neo-Medium';
  src: url('tele_neo_medium-webfont.woff2') format('woff2'),
    url('tele_neo_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
