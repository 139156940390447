.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}

.platform_icon {
  display: flex;
  flex-direction: column;
  flex: 25% 1;
  align-items: flex-end;
}
.icon {
  width: 3rem;
  height: 3rem;
}

.action {
  margin-top: var(--gutterSpacing);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app_id_row {
  flex-basis: 70%;
}
.button svg path {
  fill: var(--magenta);
}
.button:hover {
  background-color: transparent;
}
