.container {
  display: flex;
  justify-content: space-between;
}

.label_container {
  flex: 0 1 49%;
}

.label_header {
  font-family: var(--fontUltra);
  margin-bottom: 10px;
}

.list {
  overflow: hidden;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  max-height: 150px;
}
