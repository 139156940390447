/*-- Global Variables --*/

:root {
  /*-- Colors -- */
  --primaryColor: var(--magenta);
  --primaryColorDark: var(--magenta);
  --primaryColor-hover: var(--magentaDark);
  --magenta: #e20074;
  --magentaDark: #ba0060;
  --headerText-dark: #262626;
  --bodyText-dark: #101010;
  --faqText-dark: #4a4a4a;
  --bodyText-light: #fff;
  --backgroundColor: #e9e9e9;
  --backgroundColor-dark: rgba(0, 0, 0, 0.1);
  --colorSuccess: #28a745;
  --colorWarning: #ffc107;
  --colorDanger: #dc3545;
  --colorHoverDanger: #c82333;
  --colorInfo: #17a2b8;
  --colorDisabled: #ebebeb;
  --inputBorderColor: #dadada;
  --primaryBorderColor: #e8e8e8;
  --markBackgroundColor: #2d809a;
  --markColor: #fff;
  --bodyBackgroundColor: #fff;
  --inputBackgroundColor: #f2f2f2;
  --profileBackgrondColor: #f2f2f2;
  --modalFooterBorderColor: #ccc;
  --accordionBackgroundColor: #f2f2f2;
  --faqContentBackground: #e8e8e8;
  --faqShortDescription: #757575;
  --faqDescription: #4c4c4c;

  --leftNavWidth: 250px;
  --leftCollapsedNavWidth: 5.37rem;
  --leftAsidePanel: 3.5rem;
  --topHeaderHeight: 3rem;
  --headerHeight: 50px;
  --mobileSubheaderHeight: 48px;
  --mobileFooterHeight: 72px;
  --dateRangeHeight: 9rem;
  --gutterSpacing: 20px;
  --borderRadius: 2px;
  --borderRadius-large: 8px;
  --borderRadiusTop: 2px 2px 0 0;
  --borderRadiusBottom: 0 0 2px 2px;
  --headingColor: #121212;
  --errorTextColor: #f50c0c;
  --subTitleColor: #4c4c4c;
  --pageBorderColor: #8c8c8c;
  --shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  --shadowLight: rgba(0, 0, 0, 0.2) 0 1px 2px;
  --shadowMedium: 0 3px 5px -1px rgba(230, 234, 239, 0.08),
    0 6px 10px 0 rgba(0, 0, 0, 0.09), 0 1px 18px 0 rgba(230, 234, 239, 0.5);
  --shadowHover: 0 14px 28px rgba(230, 234, 239, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  --transition: all ease-in-out 0.1s;
  --tabDisabledColor: #cccccc;
  --faqContentBackground: #e8e8e8;
  --rowLabelColor: #121212;

  /*-- Fonts -- */
  --bodyFontSize: 16px;
  --fontThin: 'Tele-Thin', var(--fontFallback);
  --fontRegular: 'Tele-Regular', var(--fontFallback);
  --fontMedium: 'Tele-Medium', var(--fontFallback);
  --fontBold: 'Tele-Bold', var(--fontFallback);
  --fontUltra: 'Tele-Ultra', var(--fontFallback);
  --fontNeoThin: 'Tele-Neo-Thin', var(--fontFallback);
  --fontNeoRegular: 'Tele-Neo-Regular', var(--fontFallback);
  --fontNeoBold: 'Tele-Neo-Bold', var(--fontFallback);
  --fontNeoUltra: 'Tele-Neo-Ultra', var(--fontFallback);
  --fontNeoMedium: 'Tele-Neo-Medium', var(--fontFallback);
  --fontFallback: sans-serif;

  /*-- Grid Columns --*/
  --columnFourth: 24%;
  --columnThird: 32%;
  --columnHalf: 49%;
  --columnFull: 100%;
  --grid-columns-1: repeat(auto-fit, minmax(100%, 1fr));
  --grid-columns-2: repeat(auto-fit, minmax(49%, 1fr));
  --grid-columns-3: repeat(auto-fit, minmax(32%, 1fr));
  --grid-columns-4: repeat(auto-fit, minmax(24%, 1fr));
  --grid-fill-columns-1: repeat(auto-fill, minmax(100%, 1fr));
  --grid-fill-columns-2: repeat(auto-fill, minmax(49%, 1fr));
  --grid-fill-columns-3: repeat(auto-fill, minmax(32%, 1fr));
  --grid-fill-columns-4: repeat(auto-fill, minmax(24%, 1fr));
  --column-gap: 1rem;
  --row-gap: 1.5rem;
}

@media screen and (max-width: 768px) {
  :root {
    --headerHeight: 52px;
  }
}
