.container {
  padding: var(--gutterSpacing);
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--gutterSpacing);
}

.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
  margin-top: 10px;
  overflow: inherit;
}

.row {
  padding: 5px 0;
  display: flex;
  align-items: center;
  color: var(--bodyText-dark);
}

.label {
  font-family: var(--fontUltra);
  width: 100%;
}

.label_span {
  font-family: var(--fontMedium);
}

.imei {
  margin: 5px auto;
  padding: 5px 0 5px 30px;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  max-height: 200px;
  overflow: scroll;
  font-family: var(--fontMedium);
}

.imei li {
  padding: 4px 0;
}

.imei_heading {
  display: block;
  margin-bottom: 10px;
  font-family: var(--fontUltra);
}

.action {
  margin: var(--gutterSpacing) 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
