.container {
  width: 100%;
}

.column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.row {
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.4rem;
}

.timestamp,
.updateDate {
  display: none;
}

.label {
  font: 1rem var(--fontNeoRegular);
  color: var(--headingColor);
}

.value {
  font: 1rem var(--fontNeoBold);
}

.raw_data {
  margin-top: 10px;
  max-width: 160px;
}

.inner_object_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.inner_object {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.8rem;
  gap: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.index {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--backgroundColor-dark);
  color: var(--primaryColor);
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.raw_data {
  padding: 1rem 0;
}
