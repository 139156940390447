.container {
  margin-bottom: var(--gutterSpacing);
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.image {
  height: 31px;
  width: 31px;
}

.title {
  margin: 0 20px;
}

.count {
  flex: 1;
}

.battery {
  display: flex;
  align-items: center;
}

.battery_icon {
  height: 20px;
  width: 45px;
}
