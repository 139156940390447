.overview {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  align-items: center;
  overflow-y: auto;
  gap: 1.5rem;
}
