.container {
  padding-top: var(--gutterSpacing);
}

.sensors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.single_sensor {
  flex: 1;
  margin: 0 2% 2% 0;
}

.single_sensor:last-of-type {
  margin-right: 0;
}

.TEMPERATURE.single-sensor,
.WLAN.single-sensor {
  flex: 0 1 32%;
  margin: 0 0 2%;
}

.list {
  margin: var(--gutterSpacing) 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list_item {
  flex: 0 0 49%;
  margin-bottom: 2%;
}

.pagination_container {
  padding: var(--gutterSpacing) 0 0;
  margin: 0 auto;
  width: 100%;
}

.no_results {
  padding: var(--gutterSpacing);
  background: var(--backgroundColor);
  font: normal 16px/1 var(--fontBold);
  border-radius: var(--borderRadius);
  margin-bottom: 3.5rem;
}

.no_results_icon {
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.no_results span {
  color: var(--colorDanger);
  font-family: var(--fontUltra);
}

.location_history {
  flex: 1;
}

.results_location {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 2rem 0;
}
.main_container {
  position: relative;
}

.history_btn_wrapper {
  position: absolute;
  right: 0;
  top: -3.5rem;
}

.history_btn_wrapper span {
  position: initial;
}

.subheading {
  font: 1.5rem var(--fontNeoBold);
  color: var(---headingColor);
  font-weight: initial;
  text-transform: uppercase;
}
.graph_container {
  margin-top: 0;
}
.date_range_panel_position {
  margin-top: calc(var(--dateRangeHeight) + 1rem);
}
.graph_container .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.custom_pagination {
  flex: initial;
  margin: 0;
}
.custom_pagination li {
  padding: 0.5rem 0.8rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
}
.controls {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.pagination_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.page_size {
  width: 13rem;
}
.custom_pagination li {
  padding: 0.5rem 0.8rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
}
.hide_history svg {
  width: 18px;
  height: 18px;
}

.filter {
  position: absolute;
  top: -4rem;
  left: 0;
}

.filter_select {
  max-width: 130px;
  width: 100%;
}

.filter label {
  width: auto;
  text-align: left;
  font-family: var(--fontBold);
  padding-right: 10px;
}

@media (max-width: 767px) {
  .results,
  .controls {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
  .full_width {
    width: 100%;
  }
  .list_item {
    flex-basis: 100%;
  }
  .date_range_panel_position {
    margin-top: calc(var(--dateRangeHeight) + 8rem);
  }
}
@media (min-width: 768px) {
  .results {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    display: flex;
  }
  .controls {
    justify-content: space-between;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .results {
    margin-bottom: var(--gutterSpacing);
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .controls {
    justify-content: space-between;
    width: auto;
  }
}
