.container {
  margin-top: var(--gutterSpacing);
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.items {
  display: grid;
  grid-template-columns: var(--grid-fill-columns-3);
  grid-gap: var(--gutterSpacing);
}

@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}
