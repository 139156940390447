.datepicker {
  height: 40px;
  font-family: var(--fontMedium);
  font-size: 14px;
  border: solid 1px #dadada;
  padding: 5px 7px;
  width: 100%;
}

.filters {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.filter {
  flex: 1 1;
  max-width: 200px;
  margin-right: 20px;
}

.filter_label {
  padding: 0 0 5px 0;
  display: block;
  font-family: var(--fontBold);
}

.select {
  width: 100%;
  height: 40px;
  border: solid 1px var(--inputBorderColor);
}

.totals {
  display: block;
  margin: 10px 0;
}

@media (max-width: 420px) {
  .filter {
    max-width: 100%;
    margin-right: 0;
  }
  .filters {
    flex-direction: column;
    gap: 1rem;
  }
}
