.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
}

.loading_text {
  font: normal 1em var(--fontUltra);
  line-height: 1.5;
}

.loading_text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 1200ms infinite;
  content: '\2026';
  width: 0;
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-basis: 23%;
  gap: 0.4rem;
}

.label {
  font: 1rem var(--fontNeoMedium);
}

.label_span {
  font: 1rem var(--fontNeoBold);
}

.label_span:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 1200ms infinite;
  content: '\2026';
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 16px;
  }
}

.spinner {
  height: 200px;
  width: 200px;
}

.sm {
  width: 80px;
  height: 80px;
}

.standard {
  width: 150px;
  height: 150px;
}

.lg {
  width: 200px;
  height: 200px;
}
