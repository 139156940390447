.button {
  background-color: var(--primaryColor);
  color: #ffffff;
  cursor: pointer;
  font-family: var(--fontBold);
  font-size: 13px;
  padding: 8px 20px;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.2s;
  border-radius: var(--borderRadius);
}

.button:hover {
  background: var(--primaryColor-hover);
}

.sm {
  line-height: 1.4;
  font-size: 11px;
  text-transform: none;
  padding: 4px 10px;
}

.lg {
  font-size: 20px;
}

.link {
  color: initial;
  text-decoration: none;
}

.danger {
  background: var(--colorDanger);
  color: #fff;
}
.danger:hover {
  background: #c82333;
}

.secondary_magenta {
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--primaryColor);
  color: var(--primaryColor);
  transition: box-shadow 100ms linear;
}
.secondary_magenta:hover {
  background: transparent;
  box-shadow: inset 0 0 0 2px var(--primaryColor);
}

.secondary,
[class*='secondary'] {
  background: transparent;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px var(--bodyText-dark);
  color: var(--bodyText-dark);
  transition: all 100ms linear;
}
.secondary:hover {
  background: transparent;
  box-shadow: inset 0 0 0 2px var(--bodyText-dark);
}

.secondary_danger:hover {
  color: #fff;
  background: var(--colorDanger);
  box-shadow: inset 0 0 0 2px var(--colorDanger);
}

.secondary_magenta:hover {
  color: #fff;
  background: var(--primaryColor);
  box-shadow: inset 0 0 0 2px var(--primaryColor);
}

.secondary_light {
  background: transparent;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #fff;
  color: #fff;
  transition: box-shadow 100ms linear;
}
.secondary_light:hover {
  background: transparent;
  box-shadow: inset 0 0 0 2px #fff;
}

.transparent {
  background: transparent;
  text-decoration: none;
  border: none;
  color: var(--bodyText-dark);
}

.transparent:hover {
  background: transparent;
  border: none;
  color: var(--bodyText-dark);
}

.delete {
  display: block;
  margin: 0 20px 10px auto;
  background-color: grey;
}

.icon {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 5px;
}

.disabled {
  background-color: var(--colorDisabled);
  transition: background-color 0.2s;
  cursor: default;
  pointer-events: none;
}
.disabled:hover {
  background: initial;
  pointer-events: none;
}

.selected {
  background: #ba0060;
  transition: background-color 0.2s;
  cursor: default;
}
