.container {
  width: 100%;
}

.inner_sub_heading {
  font: 1.5rem var(--fontBold);
}

.card {
  margin: 1.5rem 0 2.5rem;
  border-radius: 0.25rem;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0 1px 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  position: relative;
  flex-flow: column wrap;
  overflow: auto;
  padding: 1.8rem 1.25rem;
}

pre {
  background: #fff !important;
}
