.spinner {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  width: calc(100vw + var(--leftNavWidth));
  z-index: 999;
}

.results {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  width: 100%;
  grid-gap: calc(var(--gutterSpacing) - 0.2rem);
}
.config_wrapper {
  display: block;
}
.update_config {
  margin: 0.5rem 0 1rem 0;
}
.wrapper {
  position: relative;
}
.wrapper .spinner {
  position: relative;
  width: 100px;
  margin: 0 auto;
  display: block;
}

.sort_control {
  width: 8rem;
}
@media (max-width: 900px) {
  .results {
    grid-template-columns: var(--grid-columns-1);
  }
  .sort_control {
    width: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1366px) {
  .results {
    grid-template-columns: var(--grid-columns-2);
  }
}
