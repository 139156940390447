.TrackerDashboardGrid,
.TrackerDashboardGrid-row {
  display: flex;
}

.TrackerDashboardGrid-main {
  flex: 3;
  margin-right: var(--gutterSpacing);
}

.TrackerDashboardGrid-right {
  flex: 1;
}

.TrackerDashboardGrid-column {
  flex: 1;
  box-shadow: 0 0 0 1px var(--backgroundColor-dark);
  margin: 0 var(--gutterSpacing) var(--gutterSpacing) 0;
}

.TrackerDashboardGrid-column:last-of-type {
  margin-right: 0;
}

.TrackerDashboardGrid-heading-container {
  display: flex;
  max-height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  color: var(--bodyText-light);
}

.TrackerDashboardGrid-heading-container-bg {
  background: var(--backgroundColor-dark);
}

.TrackerDashboardGrid-heading-container .TrackerDashboardGrid-heading {
  margin: 0;
}

.TrackerDashboardGrid-heading {
  font-family: var(--fontBold);
  color: var(--bodyText-light);
  text-transform: uppercase;
  margin: 20px 0 0 20px;
}

.TrackerDashboardGrid-MapView-loadingElement {
  height: 100%;
}

.TrackerDashboardGrid-map-container {
  height: 350px;
  width: 100%;
  position: relative;
  margin-bottom: var(--gutterSpacing);
}

.TrackerDashboardGrid-alerts-button {
  display: block;
  margin: 20px auto;
  color: var(--primaryColorDark);
  box-shadow: inset 0 0 0 1px var(--primaryColorDark);
}
.TrackerDashboardGrid-alerts-button:hover {
  box-shadow: inset 0 0 0 2px var(--primaryColorDark);
}

@media (max-width: 660px) {
  .TrackerDashboardGrid,
  .TrackerDashboardGrid-row {
    display: block;
  }
  .TrackerDashboardGrid-main,
  .TrackerDashboardGrid-column {
    margin-right: 0;
  }
}
