.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 23%;
  gap: 0.4rem;
}
.modal_content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.modal_content_scrollbar {
  overflow: auto;
  flex: 1 1;
}

.button {
  padding: 0;
}
.column_1 {
  flex-basis: 100%;
}
@media (max-width: 767px) {
  .column_2,
  .column_3,
  .column_4 {
    flex-basis: 100%;
  }
}
@media (min-width: 768px) {
  .column_2,
  .column_3,
  .column_4 {
    flex-basis: 45%;
  }
}
@media (min-width: 1366px) {
  .column_1 {
    flex-basis: 100%;
  }
  .column_2 {
    flex-basis: 45%;
  }
  .column_3 {
    flex-basis: 30%;
  }
  .column_4 {
    flex-basis: 23%;
  }
}
