.container {
  background: #fff;
  padding: var(--gutterSpacing);
  box-shadow: var(--shadow);
}

.button_container {
  margin: var(--gutterSpacing) 10px 0 0;
  display: flex;
  justify-content: flex-end;
}

.file_upload label {
  border-radius: var(--borderRadius);
  outline: none;
  width: 100%;
  background: #fff;
  padding: 0.75rem;
  font: 1rem var(--fontNeoBold);
  color: var(--headingColor);
  border: 0.12rem var(--inputBackgroundColor);
  background-color: var(--inputBackgroundColor);
}

.display {
  display: flex;
  flex-direction: row;
  row-gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.item {
  flex-basis: 23%;
}

.imei_id {
  display: flex;
  font: 1rem var(--fontNeoBold);
  padding-bottom: 0.25rem;
}

@media (max-width: 767px) {
  .item {
    flex-basis: 100%;
  }

  .display {
    row-gap: 0.5rem;
  }
}
