.toolbar_item {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-bottom: 10px;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 999;
  width: calc(100vw + var(--leftNavWidth));
}
.items {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card_header,
.card_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.card_footer {
  margin-bottom: 0;
  padding-top: 1.5rem;
}

.card_title {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.action_controls {
  display: flex;
  justify-content: right;
  gap: 1rem;
}
.product {
  font: 0.875rem var(--fontNeoRegular);
  padding-top: 0.2rem;
  color: var(--subTitleColor);
}
.display_name {
  font: 1.2rem var(--fontNeoBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
  flex-grow: 1;
}
.config_button {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 767px) {
  .action_controls {
    flex-direction: column;
  }
  .display_name {
    overflow: visible;
    white-space: initial;
  }
}
