.container {
  align-items: center;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-name: slide-down;
  background-color: #ffffff;
  box-shadow: var(--shadowMedium);
  display: flex;
  flex-direction: column;
  font-size: var(--bodyFontSize);
  justify-content: center;
  left: 50%;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  width: 400px;
  z-index: 9999;
}

.header {
  align-items: center;
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;
  padding: var(--gutterSpacing) 0;
  width: 100%;
}

.exclamation {
  align-items: center;
  animation-delay: 100ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-name: disney-slide-down;
  color: #ffffff;
  font-size: 40px;
  transform: scale(1.3, 0.7) translateY(40px);
}

.body {
  flex: 1;
  text-align: center;
  padding: 0 10px 10px;
}

.message {
  color: var(--bodyText-dark);
  text-align: center;
}

.footer {
  margin: 10px auto 20px;
}

@keyframes slide-down {
  from {
    top: -40px;
  }
  to {
    top: 80px;
  }
}

@keyframes disney-slide-down {
  0% {
    transform: scale(0.2, 1.9) translateY(-60px);
  }
  30% {
    transform: scale(0.7, 1.2) translateY(-30px);
  }
  60% {
    transform: scale(1.9, 0.4) translateY(120px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}
