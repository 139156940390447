.AddSingleDevice-container {
  width: 30%;
  box-shadow: 0 0 0 1px var(--backgroundColor-dark);
  margin: 0 20px var(--gutterSpacing) 0;
}

.AddSingleDevice-header {
  background: var(--backgroundColor);
  padding: 10px 30px 10px 30px;
  position: relative;
}

.AddSingleDevice-body {
  padding: 30px 125px 30px 30px;
}

.AddSingleDevice-input {
  font-size: 16px;
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid grey;
}

.AddSingleDevice-label {
  font-size: 14px;
  color: grey;
}

.AddSingleDevice-add-div {
  display: flex;
  justify-content: center;
  margin-top: 85px;
}
