.container {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}
.heading {
  color: var(--headingColor);
  font: 1.5rem var(--fontNeoMedium);
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iot_header .heading {
  font: 1.25rem var(--fontNeoMedium);
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.items {
  flex: 45% 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: var(--headingColor);
  font: 1rem/1.3 var(--fontNeoMedium);
  text-transform: capitalize;
}

.row .items span {
  text-transform: capitalize;
  font: 1rem/1.3 var(--fontNeoBold);
}

.grid4 .items {
  flex-basis: 20%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step_count_wrapper {
  margin-top: 1rem;
}

.iot_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.iot_header .title {
  flex-basis: 55%;
  overflow: hidden;
}

.iot_header .action_controls {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}

@media (max-width: 767px) {
  .results {
    grid-template-columns: var(--grid-columns-2);
  }
  .iot_header .action_controls {
    flex-direction: column;
  }
  .heading {
    word-wrap: break-word;
    white-space: initial;
  }
}

@media (max-width: 460px) {
  .results {
    grid-template-columns: var(--grid-columns-1);
  }
}
