.container {
  margin: 0 auto;
  text-align: center;
}
.content {
  margin-top: 20px;
}
.t_img
{
  margin-top: 5%;
}
.t_button{
  background-color: #e20074;
  color: white;
  border-color: #e20074;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  width: 256px;
  height: 43px;
  /* //styleName: Regular/Body/LG/SemiBold; */
font-family: var(--FontFamilyfont-family-body);
font-size: var(--FontSizeBodyfont-size-body-lg);
font-weight: 600;
line-height: var(--LineHeightBodyline-height-body-lg);

}
.t_name{
  /* styleName: Regular/Body/LG/SemiBold; */
  font-family: var(--FontFamilyfont-family-body);
  font-size: var(--FontSizeBodyfont-size-body-lg);
  font-weight: 600;
  line-height: var(--LineHeightBodyline-height-body-lg);
  text-align: center;
  color: #4C4C4C;
  padding-top: 9%;

}
.t_sname{
  /* //styleName: Regular/Body/MD/Regular; */
  font-family: var(--FontFamilyfont-family-body);
  font-size: var(--FontSizeBodyfont-size-body-md);
  font-weight: 400;
  line-height: var(--LineHeightBodyline-height-body-md);
  text-align: center;
  color: #4C4C4C;

}
.t_h1Class{
  font-family: var(--FontFamilyfont-family-headline);
  font-size: var(--FontSizeHeadlinefont-size-headline-md);
  font-weight: 700;
  line-height: var(--LineHeightHeadlineline-height-headline-md);
  text-align: center;
  color: #4C4C4C;
  height: 90px;
  gap: 0px;

}
@media (max-width: 600px) {
.t_img {
  margin-top: 39%;
  margin-bottom: 7%;
}
.t_name{
  /* font-size: x-large; */
  padding-top: 32%;
  font-family: var(--FontFamilyfont-family-body);
  font-size: var(--FontSizeBodyfont-size-body-lg);
  font-weight: 600;
  line-height: var(--LineHeightBodyline-height-body-lg);
  text-align: center;
  color: #4C4C4C;
}
.t_button{
  font-size: 18px;
  width: 327px;
  height: 48px;
  /* //styleName: Regular/Body/LG/SemiBold; */
  font-family: var(--FontFamilyfont-family-body);
  font-size: var(--FontSizeBodyfont-size-body-lg);
  font-weight: 600;
  line-height: var(--LineHeightBodyline-height-body-lg);
  text-align: center;

}
.t_h1Class{
  font-family: var(--FontFamilyfont-family-headline);
  font-size: var(--FontSizeHeadlinefont-size-headline-md);
  font-weight: 700;
  line-height: var(--LineHeightHeadlineline-height-headline-md);
  text-align: center;
  color: #4C4C4C;
  height: 90px;
  gap: 0px;
}
}