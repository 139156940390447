@import 'static/assets/fonts/webfont.css';
@import 'static/assets/css/variables.css';
@import 'static/assets/css/themes.css';
@import '../node_modules/@tmobile/tmo-bps-syncup-web-component-lib/dist/index.css';

* {
  outline: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bodyBackgroundColor);
  margin: 0;
  font-family: var(--fontMedium);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

input,
select,
textarea {
  border: solid 1px var(--inputBorderColor);
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.select__control {
  border-color: var(--inputBorderColor) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

mark {
  color: #262626;
  background: rgba(255, 193, 7, 0.2);
}

iframe {
  border: none;
}

strong {
  font-family: var(--fontUltra);
  font-weight: normal;
}

.magenta {
  color: var(--primaryColor);
}

.danger {
  color: var(--colorDanger);
}

.success {
  color: var(--colorSuccess);
}

.Global-background-dark-gray {
  color: var(--backgroundColor-dark);
}

.Global-magenta-button {
  background-color: var(--primaryColor);
  border: 1px solid #89888b;
  color: #ffffff;
  cursor: pointer;
  font-family: var(--fontBold);
  font-size: 13px;
  padding: 8px 20px;
  text-transform: uppercase;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.ql-tooltip {
  left: 0 !important;
}
