.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  flex: 0 1 32%;
  margin-bottom: 2%;
  overflow-y: auto;
}

.card:nth-of-type(even) {
  margin-right: 0;
}

.row {
  display: flex;
  padding-bottom: 5px;
}

.label_heading {
  font-family: var(--fontBold);
}

.label_content {
  padding-left: 5px;
}

.results {
  overflow: scroll;
}

.modal {
  margin-top: var(--gutterSpacing);
}

@media (max-width: 690px) {
  .DeviceLogsTable-box {
    width: 100%;
    margin: var(--gutterSpacing) 0 0;
  }
}
