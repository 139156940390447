.wrapper {
  background: var(--magenta);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  height: var(--topHeaderHeight);
  z-index: 999;
  width: 100%;
  justify-content: space-between;
}

.logo svg {
  height: 1.2rem;
}

.right_section {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 2rem;
  flex-grow: 1;
}

.left-section {
  flex-basis: 40%;
}

.help {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.env_dropdown {
  color: #fff;
  border-radius: 5rem;
  cursor: pointer;
  padding: 0.3rem 1rem;
  text-transform: uppercase;
}

.env_dropdown span {
  color: #fff;
}

.env_dropdown:hover {
  background: #bf0062;
}

.active_item {
  color: #fff;
}

.env_dropdown div {
  top: 1.6rem;
}

.active_item .item:hover {
  color: var(--magenta);
}

.item {
  text-transform: uppercase;
  cursor: pointer;
  font: 1rem var(--fontNeoBold);
  color: #121212;
  padding: 0 1rem;
}

.item:first-child {
  margin-top: 1rem;
}

.item:last-child {
  margin-bottom: 0.5rem;
}

.help_label {
  line-height: 1.7rem;
}

.dropdown_text_mobile {
  display: none;
}

@media (max-width: 767px) {
  .dropdown_text {
    display: none;
  }

  .dropdown_text_mobile {
    display: block;
  }
}
