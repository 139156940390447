.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.textarea {
  margin: var(--gutterSpacing) 0;
}

.suggestions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.query_buttons {
  align-self: flex-end;
  margin-top: var(--gutterSpacing);
  display: flex;
  gap: 0.5rem;
}

.query_button {
  margin-left: 10px;
}

.suggestion_button {
  font: 12px/1.5 var(--fontBold);
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: 16px;
  letter-spacing: 0.0178571429em;
  height: 32px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px !important;
  margin: 8px;
  outline: none;
  cursor: pointer;
  border: none !important;
  box-shadow: none;
  transition: all ease-in-out 0.1s !important;
}

.suggestion_button:hover {
  color: initial !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.suggestion_button_active {
  background: var(--colorSuccess) !important;
  color: var(--bodyText-light) !important;
}
.heading {
  font: 1.4rem var(--fontNeoBold);
}
.textarea {
  background-color: #f2f2f2;
  border-radius: 0.25rem;
  padding: 0.75rem;
  font: 1rem 'Tele-Neo-Regular';
  color: #8c8c8c;
  border: 0;
  margin-bottom: 0.8rem;
  width: 100%;
}
