.container {
  position: relative;
  background: #fff;
  flex-wrap: wrap;
  border: 1px solid #8c8c8c61;
  border-radius: 8px;
}

.mapContainer {
  height: 150px;
}

.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.raw_data {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
}

@media (max-width: 767px) {
  .container {
    flex-basis: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    flex-basis: 48%;
  }
}
@media (min-width: 1200px) {
  .container {
    flex-basis: 31%;
  }
}
@media (min-width: 1366px) {
  .container {
    flex-basis: 23%;
  }
}
