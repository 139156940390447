.container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--column-gap);
  margin: 2rem 0 2rem 0;
}
.row {
  flex-basis: 100%;
  overflow: hidden;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group_details_container label,
.group_details_container span,
.group_details_container .value {
  color: #ccc !important;
}
