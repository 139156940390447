.wrapper {
  background: var(--bodyText-dark);
  display: flex;
  align-items: center;
  position: fixed;
  top: var(--topHeaderHeight);
  height: var(--mobileSubheaderHeight);
  width: 100%;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  color: var(--bodyText-light);
  z-index: 2;
}
.product_logo {
  height: 3rem;
}
.search_title {
  color: var(--bodyText-light);
  font: 1.3rem var(--fontNeoMedium);
  text-transform: uppercase;
  margin: 0;
}
.search_description {
  font: 0.75rem var(--fontNeoRegular);
}
.action_icon path {
  fill: var(--bodyText-light);
}
