.container {
  align-items: center;
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 5px;
  min-height: 50px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
