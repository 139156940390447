.overview {
  display: flex;
  flex-direction: column;
}
.container {
  background: var(--bodyBackgroundColor);
}

@media (max-width: 1200px) {
  .container .dashboard_tabs {
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    overflow: scroll;
    z-index: 1;
    margin: 0;
  }
}
