.container {
  margin-bottom: var(--gutterSpacing);
}

.label {
  font: 13px/2.5 var(--fontUltra);
  color: var(--bodyText-dark);
  padding-right: 20px;
}

.button {
  cursor: pointer;
  font: 13px/1.5 var(--fontBold);
  border: none;
  border-radius: 50px;
  color: var(--bodyText-dark);
  background-color: var(--backgroundColor);
  padding: 5px 13px;
  margin-right: 5px;
  transition: all ease-in-out 0.15s;
}
.button:last-of-type {
  margin: 0;
}

.button:hover {
  background-color: var(--backgroundColor-dark);
}

.button_active,
.button_active:hover {
  color: #fff;
  background: var(--primaryColor);
}

.custom_datepicker {
  width: 100%;
  border: 0;
  position: absolute;
  left: 0;
  padding: 1rem 1rem 1.5rem;
  padding-top: 1rem;
  background: #f8f8f8;
  margin: 2rem 0;
  border-radius: 4px;
}
