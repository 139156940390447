.FloorPlan {
  flex: 1;
}

.FloorPlan-hotspots {
  position: relative;
  width: 587px;
  margin: 0 auto;
}

.FloorPlan-hotspot {
  position: absolute;
}

.FloorPlan-hotspot-icon {
  fill: transparent;
  cursor: pointer;
  width: 34px;
  height: 40px;
  padding: 10px;
  animation: pulse 1.5s infinite;
}

.FloorPlan-hotspot-icon:hover {
  animation: none;
}

.FloorPlan-hotspot#hotspot1 {
  top: 90px;
  left: 41px;
}

.FloorPlan-hotspot#hotspot2 {
  top: 90px;
  left: 146px;
}

.FloorPlan-hotspot#hotspot3 {
  top: 90px;
  right: 140px;
}

.FloorPlan-hotspot#hotspot4 {
  top: 90px;
  right: 28px;
}

.FloorPlan-hotspot#hotspot5 {
  top: 140px;
  left: 41px;
}

.FloorPlan-hotspot#hotspot6 {
  top: 140px;
  left: 140px;
}

.FloorPlan-hotspot#hotspot7 {
  top: 140px;
  right: 140px;
}

.FloorPlan-hotspot#hotspot8 {
  top: 140px;
  right: 28px;
}

.FloorPlan-hotspots-tooltip::after {
  bottom: calc(100% + 20px) !important;
}
.FloorPlan-hotspots-tooltip::before {
  bottom: calc(100% + 15px) !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}
