.Header-commercial-container {
  display: flex;
  height: var(--headerHeight);
  border-bottom: solid 1px var(--backgroundColor-dark);
  padding: 10px var(--gutterSpacing);
  align-items: center;
  align-content: space-around;
  background: #fff;
  z-index: 99;
}

.Header-commercial-logo {
  flex: 6;
}

.Header-commercial-logo img {
  max-width: 150px;
}

.Header-commercial-actions {
  display: inherit;
}

.Header-commercial-action-btn {
  margin-right: 10px;
  border-radius: var(--borderRadius);
  line-height: 0;
}

.Header-commercial-action-btn-dashboard {
  padding: 8px;
}

.Dashboard-layout {
  width: 28px;
  height: 20px;
  fill: transparent;
}

@media (max-width: 660px) {
  .Header-commercial-container {
    margin: 0;
    width: 100%;
  }
  .Header-commercial-logo img {
    max-width: 100px;
  }
}
