.action_controls {
  position: absolute;
  top: -4rem;
  left: 0;
}

.filter_select {
  max-width: 130px;
  width: 100%;
}

.filter label {
  width: auto;
  text-align: left;
  font-family: var(--fontBold);
  padding-right: 10px;
}

.safezone_toggle {
  padding: 5px 10px;
  background: #fff;
  border-radius: var(--borderRadius);
  font-family: var(--fontBold);
  cursor: pointer;
  color: var(--bodyText-dark);
}

.marker_toggle {
  padding: 5px 10px;
  background: #fff;
  border-radius: var(--borderRadius);
  font-family: var(--fontBold);
  cursor: pointer;
  color: var(--bodyText-dark);
}

.marker_toggle_label {
  width: 100%;
  cursor: pointer;
  padding-right: 10px;
  vertical-align: middle;
}

.tooltip:after {
  text-align: left;
  width: 100%;
}
.action_controls,
.map_controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.map_controls {
  justify-content: end;
}

@media (max-width: 767px) {
  .action_controls,
  .map_controls {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
  }
  .action_controls {
    position: absolute;
    top: -8rem;
    left: 0;
  }
  .filter {
    width: 100%;
  }
}
