.AddDevice-back-btn {
  all: unset;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
}

.AddDevice-angle-left {
  position: relative;
  top: 2px;
  font-size: 14px;
}

.AddDevice-main {
  display: flex;
}
