.category_wrapper {
  background-color: #ffffff;
  color: #6a6a6a;
  padding: 1rem 0;
  font: 1.25rem/1 var(--fontRegular);
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: solid 1px #757575;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
}

.sub_category {
  font: 1rem/1 var(--fontRegular);
}
.category_wrapper:first-child .icon svg {
  height: 1.2rem;
  width: 1.5rem;
}

.sub_category_title {
  font: 1.1rem/1 var(--fontBold);
}

.category_title {
  display: flex;
  gap: 1rem;
  width: 90%;
}

.short_desc {
  margin: 0.2rem 0 0;
  font: 1rem var(--fontRegular);
  color: var(--faqShortDescription);
  width: 90%;
}

.product_icon {
  margin-right: 0.4rem;
}

.product_icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.title {
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
}
.title_wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.icon_wrapper {
  display: flex;
  align-items: center;
}

.input_wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  width: 10%;
}

.input_wrapper input[type='number'] {
  width: 4rem;
  cursor: pointer;
  appearance: none;
}

.input_wrapper input::-webkit-outer-spin-button,
.input_wrapper input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.input_wrapper svg {
  cursor: pointer;
  margin-left: 15px;
}

.sticky_tiles {
  color: var(--headingColor);
  height: auto;
}

.accordion_row {
  font: 1.12rem var(--fontRegular);
  color: var(--faqText-dark);
  padding: 0.5rem 1rem 0.5rem 1rem;
  background: var(--accordionBackgroundColor);
}

.accordion_row .accordion_column {
  padding: 1rem;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px #00000033;
}

.accordion_row .accordion_column.open {
  border-bottom-right-radius: 0;
}
.accordion_content {
  background-color: #fff;
  padding: 0;
}

.heading {
  margin: 0 0 var(--gutterSpacing) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: left;
  flex-direction: column;
  color: var(--bodyText-dark);
}

.details_wrapper {
  display: flex;
  align-items: self-start;
}

.details_wrapper a {
  text-decoration: underline;
  color: var(--magenta);
}

.description {
  font: 1rem/1.4rem var(--fontNeoRegular);
  background-color: var(--backgroundColor);
  margin: 0;
  color: var(--faqDescription);
  padding: 0 1rem;
}

.icon svg path {
  fill: #333;
}
.category_title .content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.value {
  color: var(--headingColor);
  font: 1rem var(--fontNeoBold);
}
@media (max-width: 767px) {
  .category_wrapper {
    height: 6rem;
  }
}
