.input_container {
  display: flex;
  text-align: center;
  font: 1.25rem var(--fontNeoRegular);
  flex-direction: column;
  background-color: none;
}

.label {
  display: flex;
  font: 1rem var(--fontNeoMedium);
  align-items: flex-start;
  color: #4c4c4c;
  padding-bottom: 0.4rem;
}

.text_input {
  flex: 1;
  padding: 8px;
  border-radius: var(--borderRadius);
  margin-top: 0.4rem;
  border-radius: 4px;
}

.action_row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  padding: 12px 24px;
}

.modal_heading {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 1.25rem var(--fontNeoRegular);
  background-color: none;
}

.img {
  height: 1rem;
  width: 1rem;
}
