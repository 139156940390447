.container {
  background: var(--bodyBackgroundColor);
}
.firmware_container {
  background: var(--bodyBackgroundColor);
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  overflow: inherit;
}

.item {
  flex-basis: var(--columnThird);
  margin-bottom: 2%;
}
@media (max-width: 1000px) {
  .item {
    flex-basis: var(--columnHalf);
  }
}
@media (max-width: 767px) {
  .item {
    flex-basis: var(--columnFull);
  }
}
