.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.25);
}

.container {
  position: relative;
  max-width: 80%;
  width: auto;
  max-height: 500px;
  background: white;
  border-radius: 2px;
  box-shadow: 0 3px 5px -1px rgba(230, 234, 239, 0.08),
    0 6px 10px 0 rgba(0, 0, 0, 0.09), 0 1px 18px 0 rgba(230, 234, 239, 0.5);
  overflow-y: auto;
}

@media (min-width: 660px) {
  .container {
    max-width: 960px;
    min-width: 500px;
  }
}

@media (max-width: 660px) {
  .container {
    width: 100%;
  }
}

.header {
  display: flex;
  padding: 15px 5px 5px 15px;
  background-color: #e20074;
  border-radius: 2px 2px 0 0;
  fill: #ffffff;
  position: sticky;
  top: 0;
  z-index: 999;
}

.danger {
  background-color: #dc3545;
}

.close {
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 10px;
  position: relative;
  top: -4px;
}

.title {
  flex-grow: 1;
  margin: 0;
  color: #ffffff;
}

.content {
  padding: 15px;
}

.slide .container {
  position: absolute;
  height: 100%;
  max-height: initial;
  box-shadow: initial;
  border-radius: initial;
  overflow: hidden;
  min-width: initial;
}
.slide .content {
  height: calc(100% - 3.125rem);
}
.slide .lg {
  position: absolute;
  right: -32rem;
  width: 32rem;
  transition: all 0.5s;
}
.slide .md {
  position: absolute;
  right: -20rem;
  width: 20rem;
  transition: all 0.5s;
}
.slide .show {
  right: 0%;
}
.slide .header {
  background-color: initial;
  border-bottom: 0.125rem solid #ccc;
  margin: 0 1rem;
  padding: 1rem 0 0.25rem;
  text-transform: uppercase;
  fill: #121212;
}
.slide .header h2 {
  color: #e20074;
  font-family: 'Tele-Neo-Bold';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete .container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.delete .header {
  background: white;
  padding: 0;
}

.delete .title {
  display: flex;
  justify-content: center;
  font: 1.25rem 'Tele-Neo-Bold';
  color: #121212;
  padding-bottom: 1.5rem;
}

.delete .content {
  padding: 0;
}
