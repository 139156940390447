.container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
}

.input {
  display: none;
}

.column {
  margin-bottom: 5px;
}

.title {
  padding-bottom: 10px;
}

.input_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input_unclaim {
  margin-bottom: 5px;
  display: flex;
}

.reset {
  margin-right: 10px;
  flex: 1;
}

.action {
  flex: 1;
}

.action_row {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  justify-content: flex-end;
}

.controls {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.label {
  width: 100%;
  border: solid 1px #89888b;
  padding: 13px 7px;
  margin-right: 10px;
  font-size: 14px;
  flex: 5;
}

.label_disabled {
  opacity: 0.4;
}

.upload_placeholder {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.subtitle {
  font: 0.8rem var(--fontNeoThin);
  color: var(--pageBorderColor);
  margin-top: 0.2rem;
}

.file_name {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  font: 1rem var(--fontNeoBold);
}

.file_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0.5rem;
}

.delete_icon {
  border: 0;
}

@media (max-width: 767px) {
  .subtitle {
    margin-top: 0.4rem;
  }

  .action_row {
    flex-direction: column;
    align-items: unset;
  }

  .controls {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .toggle {
    align-self: flex-end;
  }
}
