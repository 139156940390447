.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.label {
  font-family: var(--fontNeoRegular);
  margin-right: 5px;
}
.value {
  font-family: var(--fontNeoBold);
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
}
