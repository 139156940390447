.items {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
}

.message {
  font: 1rem var(--fontNeoBold);
  text-align: center;
}

@media (max-width: 1024px) {
  .items {
    grid-template-columns: var(--grid-columns-2);
  }
}

@media (max-width: 768px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }
}
