.column {
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
}

.networks {
  padding: 0 0 0 var(--gutterSpacing);
  margin: 0;
}

.tooltip:after {
  text-align: left;
  width: 100%;
}

.tooltip_icon {
  cursor: pointer;
  position: relative;
  top: 2px;
  color: var(--colorWarning);
}

.action {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.action_button {
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-basis: 23%;
  gap: 0.4rem;
  padding: 0;
  word-break: break-all;
}

.row .label {
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}

.span {
  font: 1rem var(--fontNeoBold);
}
@media (max-width: 767px) {
  .row {
    flex-basis: 100%;
  }
}
