.ordering_row_wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  background: #fff;
  border-radius: var(--borderRadius);
  border: 1px solid var(--backgroundColor-dark);
  padding: var(--gutterSpacing);
  display: flex;
  align-items: center;
  left: calc(var(--leftNavWidth) + var(--gutterSpacing));
  box-shadow: var(--shadowMedium);
  cursor: pointer;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  z-index: 999;
}

.ordering_spinner {
  font-size: 0.875rem;
  animation: spin infinite 2s linear;
  margin-right: 0.5rem;
}
.action_wrapper {
  display: flex;
  gap: 1rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 767px) {
  .ordering_row_wrapper {
    z-index: 9;
  }
}
