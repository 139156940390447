.container {
  position: relative;
}

.buttons {
  overflow-y: auto;
  position: absolute;
  width: 95%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.6rem;
}

.buttons_icon {
  position: relative;
  top: 1px;
  left: 3px;
}

.button {
  border-right: solid 1px;
}

.button:last-of-type {
  border: none;
}
