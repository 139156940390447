.delete_btn {
  font: 0.8rem var(--fontBold);
  color: #fff;
  padding: 0.5rem 1.25rem;
  border: none;
  text-transform: uppercase;
}
.delete_btn:hover {
  background: var(--colorHoverDanger);
}
.spinner {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  width: calc(100vw + var(--leftNavWidth));
  z-index: 999;
}
.spinner_deleting {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  background: #fff;
  height: 100%;
  z-index: 2;
}
.delete_row_wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  background: #fff;
  border-radius: var(--borderRadius);
  border: 1px solid var(--backgroundColor-dark);
  padding: var(--gutterSpacing);
  display: flex;
  left: calc(var(--leftNavWidth) + var(--gutterSpacing));
  box-shadow: var(--shadowMedium);
  cursor: pointer;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  z-index: 999;
}
.delete_row_wrapper .action_button:first-child {
  margin-right: 1rem;
}
.action_wrapper .action_button {
  margin-bottom: 1rem;
  width: 100%;
}
@media (max-width: 1400px) {
  .action_wrapper .action_button,
  .delete_demo_btn {
    font-size: 0.8rem;
  }
}
@media (max-width: 420px) {
  .delete_demo_btn {
    flex-grow: 1;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .delete_row_wrapper {
    z-index: 9;
  }
}
