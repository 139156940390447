.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--gutterSpacing);
}

.form * {
  flex: 1;
}

.file_input {
  flex-basis: 75%;
  padding: 8px;
  background: #fff;
  border-radius: var(--borderRadius);
  margin: 0 10px;
}

.action {
  padding: 10px;
}

.result {
  margin-top: var(--gutterSpacing);
  display: flex;
  flex-direction: column;
  padding: var(--gutterSpacing);
  background: var(--backgroundColor-dark);
  border-radius: var(--borderRadius);
}

.result_title {
  color: var(--colorSuccess);
  margin-bottom: var(--gutterSpacing);
}

.label {
  flex: 1;
  padding-bottom: 10px;
  font-family: var(--fontUltra);
}

.label span {
  font-family: var(--fontMedium);
}

@media (max-width: 660px) {
  .form {
    flex-wrap: wrap;
  }

  .action {
    margin-top: var(--gutterSpacing);
  }
}
