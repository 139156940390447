.container {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
  margin-bottom: var(--gutterSpacing);
}

.user_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 8px;
  border: none;
  padding: 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.user {
  display: flex;
  flex-direction: row;
}

.name_email {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.name {
  font: 1.5rem var(--fontNeoMedium);
}

.email {
  font: 1rem var(--fontNeoMedium);
}

.user_details {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1.5rem;
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-basis: 23%;
  gap: 0.4rem;
}

.label {
  font: 1rem var(--fontNeoMedium);
}

.modal_message {
  font: 1rem var(--fontNeoRegular);
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.2rem;
}

.accordion {
  display: flex;
}

.accordion_title {
  background: #ffffff;
  padding: 1rem;
  border-radius: 8px;
}

.accordion_user {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
}

.accordion_row {
  flex-basis: 100%;
}

.label_span {
  font: 1rem var(--fontNeoBold);
}

.modal_message_span {
  font: 1rem var(--fontNeoBold);
}

.accordion_column {
  background: #ffffff;
  padding: 0px;
  border-radius: 8px;
}

.version {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.user_column .row {
  flex-direction: column;
}

.avatar_container {
  width: 52px;
  height: 52px;
  align-self: center;
}

.avatar {
  background-size: cover;
  width: 100%;
  height: 100%;
  border: solid 3px #fff;
  box-shadow: var(--shadowMedium);
  display: inline-block;
  border-radius: 50%;
}

.preferences {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.preferences p {
  padding: 0;
  margin: 0;
}

@media (max-width: 660px) {
  .container,
  .column {
    display: block;
    width: 100%;
  }
}

.claim_button {
  display: flex;
}

.view_button {
  background-color: #fff;
  color: #e20074;
  text-decoration: underline;
  font: 1rem var(--fontNeoBold);
  text-transform: none;
  display: flex;
  padding: 0;
}

.view_button:hover {
  background: none;
}

.separator {
  border-bottom: 1px solid #cccccc;
  margin: 1.5rem 0;
}

.lable_status {
  font: 1rem var(--fontNeoBold);
  margin-left: 0;
}

.delete_modal .header {
  background: white;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon {
  display: flex;
  align-items: flex-start;
}

.versions_items {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.version_card {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.action_button {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

@media (max-width: 767px) {
  .action_button {
    display: flex;
    align-items: center;
  }
  .action_button {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  .accordion_user {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .user_container {
    align-items: start;
  }
  .accordion {
    display: flex;
    width: 100%;
  }
  .modal_message {
    display: inline;
  }
}
