.search_icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: 2rem;
}
.search_wrapper {
  position: fixed;
  z-index: 9999;
  bottom: 1.2rem;
  right: 1.2rem;
  cursor: pointer;
}
.explore_input_icon {
  position: absolute;
  left: 2.5rem;
  bottom: 2.9rem;
}
.close_input_icon {
  position: absolute;
  right: 2.5rem;
  bottom: 2.9rem;
  cursor: pointer;
}
.input {
  width: 100%;
  border-radius: 0.3rem;
  height: 3.6rem;
  border-bottom: solid 2px var(--magenta);
  padding: 0 2.8rem;
  background: #f2f2f2;
  font-size: 1.6rem;
  caret-color: var(--magenta);
}
.admin_search.input {
  background-color: #f2f2f2;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  width: 100%;
  border-radius: 0.25rem;
  height: 3rem;
  font-size: 1rem;
  font: 1rem 'Tele-Neo-Regular';
  color: #8c8c8c;
  border: 0.12rem #f2f2f2;
}
.admin_search_icon {
  position: absolute;
  color: var(--primaryColor);
  top: 29%;
  transform: translateY(-50%);
  left: 0.6rem;
}

.search_section {
  padding: 1.8rem;
  position: relative;
}
.no_padding {
  padding: 0;
}
.search_section.no_padding {
  padding: 0 0 1.5rem;
}
.input:focus,
.input:not(:disabled):hover {
  border: 0;
}
.no_result_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font: 1.25rem/1 var(--fontRegular);
  padding: 0 1.8rem 0;
  color: var(--faqText-dark);
}
mark {
  color: var(--markColor);
  background: var(--markBackgroundColor);
}
.faq_button {
  background: transparent;
  color: var(--faqText-dark);
  text-transform: initial;
  font: 1.25rem/1 var(--fontRegular);
  border: solid 1px #4a4a4a;
  border-radius: 2rem;
}
.faq_button:hover {
  background: transparent;
}
.search_scroll {
  height: 85%;
  overflow-y: auto;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}
.submit_question_wrapper {
  padding: 1rem 0 2rem 0;
  border-top: solid 1px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
}
.submit_text {
  font: 1rem var(--fontMedium);
}
.action_button {
  background: #fff;
  border-radius: 2rem;
  border: solid 1px #333;
  color: var(--bodyText-dark);
  text-transform: initial;
  font: 1rem var(--fontMedium);
  margin-top: 0.5rem;
}
.action_button:hover {
  background-color: #fff;
}
.search_control {
  display: flex;
  align-items: center;
}
.search_icon {
  position: absolute;
  padding-left: 0.5rem;
}
.search_icon svg {
  height: 1rem;
}
