.section {
  margin: var(--gutterSpacing) 0;
}

.things_content {
  width: 100%;
  position: relative;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  flex: 1;
  color: var(--bodyText-dark);
  font: normal 18px/1 var(--fontUltra);
}

.details {
  display: flex;
  flex-direction: column;
  margin: var(--gutterSpacing) 0 0;
}

.raw_data {
  display: flex;
  flex-direction: column;
}

.claim_button {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.things_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1rem;
}
.things_tab {
  flex-direction: column;
  width: 12rem;
  background: #f2f2f2;
  border-radius: 8px;
  padding: 1.5rem;
  border: none;
  margin-bottom: 0px;
}
.text:first-of-type {
  margin-left: 0;
}

.text a {
  font-family: var(--fontNeoUltra);
  color: var(--headingColor);
}
.disabled a {
  color: var(--tabDisabledColor);
}
@media (max-width: 767px) {
  .things_tab {
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    overflow: scroll;
    margin-bottom: 2rem;
  }
  .things_container {
    display: block;
  }
}
@media (min-width: 768px) {
  .things_tab {
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    overflow: scroll;
    margin-bottom: 2rem;
  }
  .things_container {
    display: block;
  }
}
@media (min-width: 1200px) {
  .things_tab {
    flex-direction: column;
    width: 12rem;
    overflow: auto;
    margin-bottom: 0;
    gap: initial;
  }
  .things_container {
    display: flex;
  }
}
