.container {
  background: #fff;
  border: solid 1px var(--backgroundColor-dark);
  padding: var(--gutterSpacing);
  border-radius: var(--borderRadius-large);
}
.shadow {
  box-shadow: var(--shadowLight);
}
.heading {
  font: 2.5rem var(--fontBold);
}
.page_head {
  display: flex;
  font: 1rem var(--fontBold);
  text-transform: uppercase;
}
.page_head .page_heading {
  margin: 0 0.6rem 0.6rem;
}
