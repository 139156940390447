.overview {
  display: flex;
  flex-direction: column;
}
.container {
  background: var(--bodyBackgroundColor);
}
@media (max-width: 767px) {
  .container .custom_tabs {
    z-index: 1;
    background-color: #fff;
  }
}
