.container {
  display: grid;
  grid-template-columns: var(--grid-columns-3);
  grid-gap: var(--gutterSpacing);
  justify-content: space-between;
  width: 100%;
  margin-top: var(--gutterSpacing);
}

.users {
  flex-basis: var(--columnThird);
  margin-bottom: 2%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_action {
  display: flex;
  flex-basis: 250px;
}

.loading {
  height: 20px;
}

.pagination {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin-top: 10px;
  padding: var(--gutterSpacing);
}
.items {
  display: grid;
  grid-template-columns: var(--grid-fill-columns-3);
  grid-gap: var(--gutterSpacing);
}

@media (max-width: 1200px) {
  .items {
    grid-template-columns: var(--grid-columns-1);
  }

  .users {
    flex-basis: var(--columnHalf);
  }
}
