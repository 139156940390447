.list_container {
  margin-top: 10px;
}

.list_incidents {
  font-family: var(--fontBold);
  font-size: 20px;
}

.incidents_container {
  margin-top: var(--gutterSpacing);
}
