.main_container {
  background-color: var(--bodyBackgroundColor);
}

.container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-basis: 23%;
  gap: 0.4rem;
  word-break: break-word;
}

.label {
  font: 1rem var(--fontNeoMedium);
  color: var(--headingColor);
}

.label_error {
  font: 1rem var(--fontNeoMedium);
  color: var(--errorTextColor);
}
.value {
  font: 1rem var(--fontNeoBold);
  margin-left: 0;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  row-gap: 1.5rem;
}

.action {
  margin-top: var(--gutterSpacing);
  display: flex;
  align-content: baseline;
  width: 100%;
  justify-content: space-between;
}

.link {
  margin: 10px 0 0 auto;
  text-align: center;
}

.link_icon {
  position: relative;
  top: 2px;
  left: 0;
  margin-left: 3px;
  transition: all ease-in-out 0.15s;
}

.link:hover .link_icon {
  left: 3px;
}

.avatar_container {
  width: 86px;
  height: 86px;
}

.avatar {
  background-size: cover;
  width: 100%;
  height: 100%;
  border: solid 6px #fff;
  box-shadow: var(--shadow);
  display: inline-block;
  border-radius: 50%;
}

.break_all {
  word-break: break-all;
}

.clipboard_container {
  width: 100%;
}

.clipboard {
  width: 100%;
  background-color: var(--inputBackgroundColor);
  min-height: 200px;
  margin-bottom: 1rem;
}

.clipboard::-webkit-scrollbar {
  width: 0.625rem;
  height: 0.625rem;
}

.clipboard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

.clipboard::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}
.top_content {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1rem;
}

.input_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.input_grid .input {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
}
.input_grid .input::placeholder {
  color: #ababab;
}

@media (min-width: 768px) {
  .input_grid .input {
    flex-basis: 47%;
  }
  .product_dropdown {
    width: 50%;
  }
}
.product_label {
  margin-bottom: 0.4rem;
  display: flex;
}
.submit_button svg path {
  fill: #fff;
}
.button_spinner {
  animation: spin infinite 2s linear;
  margin-right: 0.5rem;
  height: 24px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
