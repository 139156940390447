.container {
  display: flex;
  flex-direction: column;
}

.user_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
}

.user {
  display: flex;
  flex: 45% 1;
}

.user select {
  border-bottom: 0;
}

.text_area {
  height: 6rem;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

@media (max-width: 767px) {
  .user_container {
    flex-direction: column;
    flex-basis: 100%;
  }

  .user {
    width: 100%;
  }

  .action {
    justify-content: space-between;
  }
}
